import {
  keypadInit,
  pwdDecrypt,
  getSessionId,
  checkUserDevice,
  checkUserDevice_web,
  checkUserExist,
  getNoticeList,
  getBannerInfoList,
  getFaqList,
  tvhubTerms,
  getCouponList,
  registerQna,
  getQnaList,
  qnaTypeList,
  getHomebbar,
  saveLog,
  saveContentsLog,
  reqTransNo,
  reqAuthData,
  appCardSetPwd,
  verity,
  approve,
  getPresentablePoint,
  getMyCoupon,
  getSendCouponHistory,
  sendCoupon,
  givePointPresent,
  registerCoupon,
  getPointSum,
  getPartnerShipList,
  callSkpAuth,
  getPromotionInfo,
  getPromotionMoreInfo,
  joinPromotion,
  joinEventGetReward,
  getPartnerShipPoint,
  transferPartnerShipPoint,
  getCashbackInfoList,
  wGetCardCode,
  getCouponPoint,
  getCashBack,
  getTransAgree,
  setTransAgree,
  createSkpOrder,
  gifticonPointPay,
  kcpOrderApproval,
  gifticonCardPay,
  createShortLinkForWeb,
  getPointSumUserNo,
  makeTid,
  stealFamilyPoint,
  getFeeInfo,
  createShortLinkForTvpoint,
  getUserUsablePoint,
  getBuzzPoint,
  checkPayment,
  getUsableList,
  getEventInfo,
  callRelayRpServer,
  transTermId,
  getTvpayList,
  insSessionData,
  getSessionData,
  login,
  logout,
  modifyPwd,
  sendSelfAuth,
  certifyUserAuth,
  certifyUserResult,
  verifySelfAuth,
  resetPassword,
  addUser,
  deleteFitUser,
  addUserFamily,
  sendAgreementResult,
  getUser,
  getCelNoUser,
  checkUserPwd,
  getPointHistory,
  getExpPointHistory,
  manageAutoTransfer,
  getDirects,
  getUserNoticeList,
  setDirect,
  getDirectsNoMember,
  getAgrmntList,
  getFamilyList,
  setFamilyInfo,
  setAgrment,
  getAgrment,
  manageAutoCharge,
  checkTermUser,
  addTVUser,
  readNoti,
  delNoti,
  checkUser,
  setUserCel,
  setFitAlarmData,
  setAutoLogin,
  setTransferYn,
  autoLogin,
  getPartnerPoint,
  getTermsOfUserList,
  getTermsOfUse,
} from '../../apis/tvpoint';
export default {
  async keypadInit({ commit }) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await keypadInit();
    return data;
  },

  async pwdDecrypt({ commit }, params) {
    const { data } = await pwdDecrypt(params);
    return data;
  },

  async getSessionId({ commit }, params) {
    const { data } = await getSessionId(params);
    return data;
  },

  async checkUserDevice({ commit }, params) {
    const { data } = await checkUserDevice(params);
    return data;
  },
  async checkUserDevice_web({ commit }, params) {
    const { data } = await checkUserDevice_web(params);
    return data;
  },
  async checkUserExist({ commit }, params) {
    const { data } = await checkUserExist(params);
    return data;
  },
  async getNoticeList({ commit }, params) {
    const { data } = await getNoticeList(params);
    return data;
  },
  async getBannerInfoList({ commit }, params) {
    const { data } = await getBannerInfoList(params);
    switch (params.menuId) {
      case 'MW0601':
        commit('setMW0601', data.resultData);
        break;
      case 'MW0100':
        commit('setMW0100', data.resultData);
        break;
      case 'MW0102':
        commit('setMW0102', data.resultData);
        break;
      case 'MW0205':
        commit('setMW0205', data.resultData);
        break;
      case 'MW0307':
        commit('setMW0307', data.resultData);
        break;
    }

    return data;
  },
  async getFaqList({ commit }, params) {
    const { data } = await getFaqList(params);
    return data;
  },
  async tvhubTerms({ commit }, params) {
    const { data } = await tvhubTerms(params);
    return data;
  },
  async getCouponList({ commit }, params) {
    const { data } = await getCouponList(params);
    return data;
  },
  async registerQna({ commit }, params) {
    const { data } = await registerQna(params);
    return data;
  },
  async getQnaList({ commit }, params) {
    const { data } = await getQnaList(params);
    return data;
  },
  async qnaTypeList() {
    const { data } = await qnaTypeList();
    return data;
  },
  async getHomebbar({ commit }, params) {
    const result = await getHomebbar(params);
    return result;
  },

  async saveLog({ commit }, params) {
    const { data } = await saveLog(params);
    return data;
  },

  async saveContentsLog({ commit }, params) {
    const { data } = await saveContentsLog(params);
    return data;
  },

  async reqTransNo({ commit }, params) {
    const { data } = await reqTransNo(params);
    return data;
  },

  async reqAuthData({ commit }, params) {
    const { data } = await reqAuthData(params);
    this.commit('setTvPayUserNo', data.mUserNo);
    return data;
  },

  async appCardSetPwd({ commit }, params) {
    const { data } = await appCardSetPwd(params);
    return data;
  },
  async verity({ commit }, params) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await verity(params);
    return data;
  },

  async approve({ commit }, params) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await approve(params);
    return data;
  },

  // 보관함
  // jsessionid: 세션아이디
  // termType: M002002
  // deviceId: 전화번호
  async getPresentablePoint({ commit }, params) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await getPresentablePoint(params);
    return data;
  },

  // 기프티콘 포인트 조회
  async getUserUsablePoint({ commit }, params) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await getUserUsablePoint(params);
    return data;
  },

  // 쿠폰 불러오기
  // userNo : 사용자 번호
  async getMyCoupon({ commit }, params) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await getMyCoupon(params);
    return data;
  },

  // 충전권 히스토리
  // termType: M002002
  // deviceId: 전화번호
  async getSendCouponHistory({ commit }, params) {
    // store 저장이 필요한 경우 commit 사용
    const { data } = await getSendCouponHistory(params);
    return data;
  },
  async sendCoupon({ commit }, params) {
    const { data } = await sendCoupon(params);
    return data;
  },

  async givePointPresent({ commit }, params) {
    const { data } = await givePointPresent(params);
    return data;
  },
  async registerCoupon({ commit }, params) {
    const { data } = await registerCoupon(params);
    return data;
  },
  async getPointSum({ commit }, params) {
    const { data } = await getPointSum(params);
    return data;
  },
  async getPointSumUserNo({ commit }, params) {
    const { data } = await getPointSumUserNo(params);
    return data;
  },
  async getPartnerShipList({ commit }, params) {
    const { data } = await getPartnerShipList(params);
    return data;
  },
  async getPartnerShipPoint({ commit }, params) {
    const { data } = await getPartnerShipPoint(params);
    return data;
  },
  async transferPartnerShipPoint({ commit }, params) {
    const { data } = await transferPartnerShipPoint(params);
    return data;
  },
  async getTransAgree({ commit }, params) {
    const { data } = await getTransAgree(params);
    return data;
  },
  async setTransAgree({ commit }, params) {
    const { data } = await setTransAgree(params);
    return data;
  },

  // 기프티콘 서비스 불러오기
  async callSkpAuth({ commit }, params) {
    const { data } = await callSkpAuth(params);
    return data;
  },
  // 배너 상세 이벤트 가져오기
  async getPromotionInfo({ commit }, params) {
    const { data } = await getPromotionInfo(params);
    return data;
  },
  // 배너 상세 이벤트 가져오기
  async getPromotionMoreInfo({ commit }, params) {
    const { data } = await getPromotionMoreInfo(params);
    return data;
  },

  // 배너 상세 기본 가져오기
  async joinPromotion({ commit }, params) {
    const { data } = await joinPromotion(params);
    return data;
  },

  // 동부화재 이벤트 처리
  async joinEventGetReward({ commit }, params) {
    const { data } = await joinEventGetReward(params);
    return data;
  },

  // vod 캐시백 리스트
  async getCashbackInfoList({ commit }, params) {
    const { data } = await getCashbackInfoList(params);
    return data;
  },
  // vod 캐시백 받기
  async getCashBack({ commit }, params) {
    const { data } = await getCashBack(params);
    return data;
  },

  async wGetCardCode({ commit }, params) {
    const { data } = await wGetCardCode(params);
    return data;
  },

  async getCouponPoint({ commit }, params) {
    const { data } = await getCouponPoint(params);
    return data;
  },

  // 결제 화면 띄우기
  async createSkpOrder({ commit }, params) {
    const { data } = await createSkpOrder(params);
    return data;
  },

  // 포인트로만 결제
  async gifticonPointPay({ commit }, params) {
    const { data } = await gifticonPointPay(params);
    return data;
  },

  // KCP 결제 화면 띄우기
  async kcpOrderApproval({ commit }, params) {
    const { data } = await kcpOrderApproval(params);
    return data;
  },

  // KCP 결제 완료 화면
  async gifticonCardPay({ commit }, params) {
    const { data } = await gifticonCardPay(params);
    return data;
  },

  // 홈빠 단축 URL 받기
  async createShortLinkForWeb({ commit }, params) {
    const { data } = await createShortLinkForWeb(params);
    return data;
  },

  // TV포인트 단축 URL 받기
  async createShortLinkForTvpoint({ commit }, params) {
    const { data } = await createShortLinkForTvpoint(params);
    return data;
  },

  // Tid 발급
  async makeTid({ commit }, params) {
    const { data } = await makeTid(params);
    return data;
  },

  // 가족회원의 포인트 가져오기
  async stealFamilyPoint({ commit }, params) {
    const { data } = await stealFamilyPoint(params);
    return data;
  },

  // 전체 제휴사 수수료 정보 가져오기
  async getFeeInfo({ commit }, params) {
    const { data } = await getFeeInfo(params);
    return data;
  },

  // 버즈빌 평균 포인트 가져오기
  async getBuzzPoint({ commit }, params) {
    const { data } = await getBuzzPoint(params);
    return data;
  },

  // 사용처별 사용 가능포인트 조회
  async getUsableList({ commit }, params) {
    const { data } = await getUsableList(params);
    return data;
  },

  async checkPayment({ commit }, params) {
    const { data } = await checkPayment(params);
    return data;
  },

  // 프로모션 체크용
  async getEventInfo({ commit }, params) {
    const { data } = await getEventInfo(params);
    return data;
  },

  async transTermId({ commit }, params) {
    const { data } = await transTermId(params);
    return data;
  },

  async getTvpayList({ commit }, params) {
    const { data } = await getTvpayList(params);
    return data;
  },

  async callRelayRpServer({ commit }, params) {
    const { data } = await callRelayRpServer(params);
    return data;
  },
  async insSessionData({ commit }, params) {
    const { data } = await insSessionData(params);
    return data;
  },
  async getSessionData({ commit }, params) {
    const { data } = await getSessionData(params);
    return data;
  },

  async login({ commit }, params) {
    const { data } = await login(params);
    this.commit('setTvPayUserNo', data.mUserNo);
    return data;
  },
  async logout({ commit }) {
    const { data } = await logout();
    return data;
  },
  async modifyPwd({ commit }, params) {
    const { data } = await modifyPwd(params);
    return data;
  },
  async sendSelfAuth({ commit }, params) {
    const { data } = await sendSelfAuth(params);
    return data;
  },
  async certifyUserAuth({ commit }, params) {
    const { data } = await certifyUserAuth(params);
    return data;
  },
  async certifyUserResult({ commit }, params) {
    const { data } = await certifyUserResult(params);
    return data;
  },
  async verifySelfAuth({ commit }, params) {
    const { data } = await verifySelfAuth(params);
    return data;
  },
  async resetPassword({ commit }, params) {
    const { data } = await resetPassword(params);
    return data;
  },
  async addUser({ commit }, params) {
    const { data } = await addUser(params);
    return data;
  },

  async deleteFitUser({ commit }, params) {
    const { data } = await deleteFitUser(params);
    return data;
  },

  async addTVUser({ commit }, params) {
    const { data } = await addTVUser(params);
    return data;
  },

  async addUserFamily({ commit }, params) {
    const { data } = await addUserFamily(params);
    return data;
  },
  async sendAgreementResult({ commit }, params) {
    const { data } = await sendAgreementResult(params);
    return data;
  },

  async getUser({ commit }, params) {
    const { data } = await getUser(params);
    return data;
  },
  async getCelNoUser({ commit }, params) {
    const { data } = await getCelNoUser(params);
    return data;
  },

  async checkUserPwd({ commit }, params) {
    const { data } = await checkUserPwd(params);
    return data;
  },
  async getPointHistory({ commit }, params) {
    const { data } = await getPointHistory(params);
    return data;
  },
  async getExpPointHistory({ commit }, params) {
    const { data } = await getExpPointHistory(params);
    return data;
  },

  // 자동전환 등록 수정 해지 조회
  async manageAutoTransfer({ commit }, params) {
    const { data } = await manageAutoTransfer(params);
    return data;
  },

  // 자동충전 등록 수정 해지 조회
  async manageAutoCharge({ commit }, params) {
    const { data } = await manageAutoCharge(params);
    return data;
  },

  // 바로가기 리스트 가져오기
  async getDirects({ commit }, params) {
    const { data } = await getDirects(params);
    return data;
  },

  // 바로가기 정보 갱신하기
  async setDirect({ commit }, params) {
    const { data } = await setDirect(params);
    return data;
  },

  async getDirectsNoMember() {
    const { data } = await getDirectsNoMember();
    return data;
  },

  // 회원 알림 목록 검색
  async getUserNoticeList({ commit }, params) {
    const { data } = await getUserNoticeList(params);
    return data;
  },

  // 회원 알림 정보 갱신
  async readNoti({ commit }, params) {
    const { data } = await readNoti(params);
    return data;
  },

  // 회원 알림 삭제
  async delNoti({ commit }, params) {
    const { data } = await delNoti(params);
    return data;
  },

  // 화면 기준 사용자의 동의 정보 목록 호출
  async getAgrmntList({ commit }, params) {
    const { data } = await getAgrmntList(params);
    return data;
  },

  // 화면 기준 사용자의 동의 정보
  async getAgrment({ commit }, params) {
    const { data } = await getAgrment(params);
    return data;
  },

  // 화면 기준 사용자의 동의 정보 저장
  async setAgrment({ commit }, params) {
    const { data } = await setAgrment(params);
    return data;
  },

  // 가족포인트 리스트 검색
  async getFamilyList({ commit }) {
    const { data } = await getFamilyList();
    return data;
  },

  // 가족포인트 상태값 변화
  async setFamilyInfo({ commit }, params) {
    const { data } = await setFamilyInfo(params);
    return data;
  },

  // TV가입 여부 확인
  async checkTermUser({ commit }, params) {
    const { data } = await checkTermUser(params);
    return data;
  },

  // 사용자 정보 저장
  async checkUser({ commit }, params) {
    const { data } = await checkUser(params);
    return data;
  },

  // 휴대폰번호 변경
  async setUserCel({ commit }, params) {
    const { data } = await setUserCel(params);
    return data;
  },

  // 핏 알림 설정
  async setFitAlarmData({ commit }, params) {
    const data = await setFitAlarmData(params);
    return data;
  },

  // 자동로그인 설정
  async setAutoLogin({ commit }, params) {
    const { data } = await setAutoLogin(params);
    return data;
  },

  // 포인트 전환설정
  async setTransferYn({ commit }, params) {
    const { data } = await setTransferYn(params);
    return data;
  },

  // 자동로그인
  async autoLogin({ commit }, params) {
    const { data } = await autoLogin(params);
    this.commit('setTvPayUserNo', data.mUserNo);
    return data;
  },

  // 제휴사 포인트 조회
  async getPartnerPoint({ commit }, params) {
    const { data } = await getPartnerPoint(params);
    return data;
  },

  // 개인정보처리방침 목록 조회
  async getTermsOfUserList({ commit }, params) {
    const { data } = await getTermsOfUserList(params);
    return data;
  },

  // 개인정보처리방침 조회
  async getTermsOfUse({ commit }, params) {
    const { data } = await getTermsOfUse(params);
    return data;
  },
};
