<template>
  <div
    class="card"
    :class="`card-name-${card.cardCode}`"
    :style="{
      border: !activeCheck ? '4px solid #2b2d2f' : '0',
      padding: noAccount ? '20px' : '0',
      width: noAccount ? '360px' : '400px',
      height: noAccount ? '216px' : '256px',
    }"
  >
    <img
      v-if="noAccount"
      style="height: 44px"
      :src="require(`@/assets/img/fit/img_${card.cardCode}_1_f.svg`)"
      alt=""
      :style="noAccount ? '' : 'padding-top: 20px; padding-left: 20px'"
    />
    <div v-else style="width: 64px; height: 64px" :style="noAccount ? '' : 'padding-top: 20px; padding-left: 20px'"></div>
    <div v-if="noAccount">
      <div class="d-flex c-center" style="margin-top: 16px">
        <CommonText
          font-size="24"
          font-color="#ffffff"
          spacing="-0.72"
          font-weight=""
          style="line-height: 1.58; margin-right: 10px; font-family: Pretendard"
          >{{ cardName(card.cardCode) }}</CommonText
        >
        <div style="width: 1px; height: 20px; background-color: #ffffff; opacity: 0.8"></div>
        <CommonText
          font-size="24"
          font-color="#ffffff"
          spacing="-0.72"
          font-weight=""
          style="
            width: 294px;
            line-height: 1.58;
            margin-left: 10px;
            font-family: Pretendard;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
          >{{ card.goodsName }}</CommonText
        >
      </div>
      <CommonText
        font-size="24"
        font-color="#ffffff"
        spacing=""
        font-weight=""
        style="line-height: 1.58; margin-right: 16px; font-family: Pretendard; margin-top: 4px; margin-bottom: 21px"
        >{{ card.acntName }}</CommonText
      >
      <CardSelect v-if="noAccount" :option-list="pay ? dataList.option.options : dataList.option.option1" :active-check="activeCheck" />
    </div>
    <div v-else>
      <CommonText
        font-size="24"
        font-color="#ffffff"
        spacing="-0.72"
        font-weight=""
        style="line-height: 1.58; margin-top: 34px; font-family: Pretendard; margin-left: 20px"
        >{{ card.goodsName }}</CommonText
      >
      <div
        class="d-flex c-center r-space-between"
        style="
          background-color: rgba(255, 255, 255, 0.1);
          position: absolute;
          bottom: 0;
          width: 360px;
          height: 72px;
          padding-left: 20px;
          padding-right: 20px;
        "
      >
        <CommonText font-size="24" font-color="#ffffff" spacing="-0.72" font-weight="" style="line-height: 1.58; font-family: Pretendard">{{
          card.acntName
        }}</CommonText>
        <!--				<div class="d-flex c-center r-center" style="border-radius: 22px; background-color: #ffffff; width: 98px; height: 40px">-->
        <!--					<CommonText fontSize="24" fontColor="#648cff" spacing="-0.72" fontWeight="600" style="line-height: 1.58; font-family: Pretendard"-->
        <!--						>3% 적립</CommonText-->
        <!--					>-->
        <!--				</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import CardSelect from '@/components/orderPayment/CardSelect';
import { fitMixin } from '@/utils/fitMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'CardRollingContent',
  components: {
    CommonText,
    // OrderSelect,
    CardSelect,
  },
  mixins: [fitMixin],
  props: {
    dataType: {
      type: String,
      default: '',
    },
    card: {
      type: Object,
      default: () => {},
    },
    imgSize: {
      type: Object,
      default: () => {},
    },
    gapSize: {
      type: Object,
      default: () => {},
    },
    componentType: {
      type: String,
      default: '',
    },
    activeCheck: {
      type: Boolean,
    },
    pay: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      dataList: {
        option: {
          option_title: '할부개월',
          option1: [{ instType: '1', name: '일시불', installment: '00' }],
          options: [
            { instType: '1', name: '일시불', installment: '00' },
            { instType: '1', name: '2개월 할부', installment: '02' },
            { instType: '1', name: '3개월 할부', installment: '03' },
            { instType: '1', name: '4개월 할부', installment: '04' },
            { instType: '1', name: '5개월 할부', installment: '05' },
            { instType: '1', name: '6개월 할부', installment: '06' },
            { instType: '1', name: '7개월 할부', installment: '07' },
            { instType: '1', name: '8개월 할부', installment: '08' },
            { instType: '1', name: '9개월 할부', installment: '09' },
            { instType: '1', name: '10개월 할부', installment: '10' },
            { instType: '1', name: '11개월 할부', installment: '11' },
            { instType: '1', name: '12개월 할부', installment: '12' },
          ],
        },
      },
    };
  },

  computed: {
    cardName() {
      return cardCode => {
        let result = '';
        switch (cardCode) {
          case '2088':
            result = '신한';
            break;
          case '1011':
            result = '농협';
            break;
          case '2050':
            result = '비씨';
            break;
          case '2004':
            result = '국민';
            break;
          case '2081':
            result = '하나';
            break;
          case '2066':
            result = '삼성';
            break;
          case '2055':
            result = '롯데';
            break;
          case '2077':
            result = '현대';
            break;
        }

        return result;
      };
    },
    mainIdx() {
      return this.$store.state.common.mainIdx;
    },
    ...mapGetters(['isLogin', 'getDeviceType']),
    noAccount() {
      return this.card.cardCode !== 'P088';
    },
  },

  watch: {
    item: {
      immediate: true,
      handler(newVal) {},
    },
  },

  created() {
    if (this.dataType !== 'fitcol') {
      this.isShow = true;
    }
    // this.$store.state.orderPayment.selectCard = { instType: '1', name: '일시불', installment: '00' };
    this.$store.state.orderPayment.selectDiscount = { instType: '1', name: '일시불', installment: '00' };
    // this.$utils.printLog(this.pay);
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 360px;
  height: 216px;
  padding: 20px;
  border-radius: 16px;
  background-color: #76787a;
  margin-right: 24px;
}
.card-name-2055 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #e3282a;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2055:after {
  //content: '롯데';
}

.card-name-1011 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #00b159;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-1011:after {
  //content: '농협';
}

.card-name-2077 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #2d2d2d;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2077:after {
  //content: '현대';
}

.card-name-2066 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #0060a9;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2066:after {
  //content: '삼성';
}

.card-name-2081 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #00928f;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2081:after {
  //content: '하나';
}

.card-name-2050 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #ec3345;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2050:after {
  //content: '비씨';
}

.card-name-2004 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #766c62;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2004:after {
  //content: '국민';
}

.card-name-2088 {
  //display: inline-block;
  //width: 64px;
  //height: 38px;
  //line-height: 38px;
  //border-radius: 4px;
  background-color: #45a3f5;
  //color: #fff;
  //font-size: 24px;
  //letter-spacing: -1.2px;
  //text-align: center;
  //margin-top: 32px;
  //margin-right: 14px;
  //box-sizing: border-box;
}

.card-name-2088:after {
  //content: '신한';
}
</style>
