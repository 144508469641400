import FTSettings from './ft-settings';

const urlMaps = {
  LOCAL: {
    BASE_URL: 'http://localhost:8080',
    PAI_WEBSOCKET_URL: 'ws://121.189.14.109:15674/ws',
    FCM_BASE_URL: 'https://iid.googleapis.com/iid/',

    // FIT URL( 현재 TV포인트 URL이 FIT으로 변경)
    FIT_BASE_URL: 'https://dev.tvpoint.co.kr:4443/FIT_API_V1', // 개발
    FIT_BASE_URL2: 'https://dev.tvpoint.co.kr:4443/FIT_API_V2', // 개발(주문결제)
    // FIT_BASE_URL: 'https://tms.tvpoint.co.kr:10443/FIT_API_V1', // STG

    FIT_ORDER_RP: 'https://dev.tvpoint.co.kr:4443/FIT-ORDER-RP-V2', // 개발(주문결제)

    //FIT (elixir) URL
    FITSCH_BASE_URL: 'https://project.homebbar.com/fitsch_api/v1/',
    HOMEBAR_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA7hwLXKQpnzFiwxsOTuv6lcyZJGULJwCM',
    PAI_BASE_URL: 'https://project.homebbar.com/pai_api/v1/',

    BS_BASE_URL: 'https://project.homebbar.com/bs_api/v1/', // 빠숍
    HB_BASE_URL: 'https://project.homebbar.com/api/v1/', // 빠숍

    // TV포인트 앱
    // TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDAxzBg3noOfzQCl5ZpOK3Zt3q_k8RiISI',
    TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDCr5VKVaY99FEf7K_wD7E6G9FxCoGu4qk',

    // TV포인트 기프티콘 URL
    SKP_BASE_URL: 'http://nstgopoc.gifticon.com/',

    // 앱카드 인증 URL
    // APPCARD_MODULE_BASE_URL: 'https://project.tvhub.co.kr:1443/APPCARD-AUTH/index.html#/',
    // APPPAY_MODULE_BASE_URL: 'https://project.tvhub.co.kr:1443/APPPAY-AUTH/index.html#/',
    APPCARD_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10488/APPCARD-AUTH/index.html#/', //STG
    APPPAY_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10488/APPPAY-AUTH/index.html#/', //STG
  },
  DEV: {
    // BASE_URL: 'http://tmsdev.tvpoint.co.kr',
    BASE_URL: 'https://dev.tvpoint.co.kr:4443',
    PAI_WEBSOCKET_URL: 'ws://121.189.14.109:15674/ws',
    FCM_BASE_URL: 'https://iid.googleapis.com/iid/',

    // FIT URL( 현재 TV포인트 URL이 FIT으로 변경)
    FIT_BASE_URL: 'https://dev.tvpoint.co.kr:4443/FIT_API_V1', // 개발
    FIT_BASE_URL2: 'https://dev.tvpoint.co.kr:4443/FIT_API_V2', // 개발(주문결제)

    FIT_ORDER_RP: 'https://dev.tvpoint.co.kr:4443/FIT-ORDER-RP-V2', // 개발(주문결제)

    // FIT (elixir) URL
    FITSCH_BASE_URL: 'https://project.homebbar.com/fitsch_api/v1/',
    HOMEBAR_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA7hwLXKQpnzFiwxsOTuv6lcyZJGULJwCM',
    PAI_BASE_URL: 'https://project.homebbar.com/pai_api/v1/',

    BS_BASE_URL: 'https://project.homebbar.com/bs_api/v1/', // 빠숍
    HB_BASE_URL: 'https://project.homebbar.com/api/v1/', // 빠숍

    // TV포인트 앱
    TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDAxzBg3noOfzQCl5ZpOK3Zt3q_k8RiISI',
    // TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDCr5VKVaY99FEf7K_wD7E6G9FxCoGu4qk',

    // TV포인트 기프티콘 URL
    SKP_BASE_URL: 'http://nstgopoc.gifticon.com/',

    // 앱카드 인증 URL
    // APPCARD_MODULE_BASE_URL: 'https://project.tvhub.co.kr:1443/APPCARD-AUTH/index.html#/',
    // APPPAY_MODULE_BASE_URL: 'https://project.tvhub.co.kr:1443/APPPAY-AUTH/index.html#/',
    // APPCARD_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10443/APPCARD-AUTH/index.html#/', //STG
    APPCARD_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10488/APPCARD-AUTH/index.html#/', //STG
    APPPAY_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10488/APPPAY-AUTH/index.html#/', //STG

    // 홈빠 클라이언트 URL
    HOME_BBAR_URL: 'https://project.homebbar.com/hband/index.html',

    // v-market URL
    V_MARKET_QUIZ_URL: 'https://rapitest.helpstore.shop/mission/quiz/list',
    V_MARKET_PLACE_URL: 'https://rapitest.helpstore.shop/mission/place/list',
  },
  STG: {
    //웹 화면 노출 url
    BASE_URL: 'https://www.fitcollabo.com:10443',
    PAI_WEBSOCKET_URL: 'ws://121.189.14.109:15674/ws',
    FCM_BASE_URL: 'https://iid.googleapis.com/iid/',

    // FIT URL( 현재 TV포인트 URL이 FIT으로 변경)
    FIT_BASE_URL: 'https://fitcollabo.com:10443/FIT_API_V1', // STG

    // FIT (elixir) URL
    FITSCH_BASE_URL: 'https://www.fitcollabo.com:18443/fitsch_api/v1/', //STG
    // FITSCH_BASE_URL: 'https://www.homebbar.com:10443/fitsch_api/v1/', //STG
    HOMEBAR_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA7hwLXKQpnzFiwxsOTuv6lcyZJGULJwCM',
    PAI_BASE_URL: 'https://www.fitcollabo.com:18443/pai_api/v1/',
    // PAI_BASE_URL: 'https://www.homebbar.com:10443/pai_api/v1/',

    BS_BASE_URL: 'https://www.fitcollabo.com:18443/bs_api/v1/', // 빠숍
    // BS_BASE_URL: 'https://www.homebbar.com:10443/bs_api/v1/', // 빠숍
    HB_BASE_URL: 'https://www.homebbar.com:10443/api/v1/', // 빠숍

    // TV포인트 앱
    // TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDAxzBg3noOfzQCl5ZpOK3Zt3q_k8RiISI',
    TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDCr5VKVaY99FEf7K_wD7E6G9FxCoGu4qk',

    // TV포인트 기프티콘 URL
    SKP_BASE_URL: 'https://opoc.gifticon.com/',

    // 앱카드 인증 URL
    // APPCARD_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10443/APPCARD-AUTH/index.html#/',
    APPCARD_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10488/APPCARD-AUTH/index.html#/', //STG
    APPPAY_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:10488/APPPAY-AUTH/index.html#/',

    // 멜론 로그인 페이지 URL
    MELON_LOGIN_URL: 'https://cbt-alliance.melon.com/internal/v2/auth/melon',

    // 홈빠 클라이언트 URL
    HOME_BBAR_URL: 'https://www.homebbar.com:10443/hband/index.html',

    // v-market URL
    V_MARKET_QUIZ_URL: 'https://rapitest.helpstore.shop/mission/quiz/list',
    V_MARKET_PLACE_URL: 'https://rapitest.helpstore.shop/mission/place/list',
  },
  PRD: {
    BASE_URL: 'https://www.fitcollabo.com', // 상용

    PAI_WEBSOCKET_URL: '',
    // FIT URL( 현재 TV포인트 URL이 FIT으로 변경)
    FIT_BASE_URL: 'https://www.fitcollabo.com/FIT_API_V1',
    FCM_BASE_URL: 'https://iid.googleapis.com/iid/',

    //TODO - hchwang PRD,STG fitsch url 확인 필요ㄷ

    // FIT (elixir) URL
    // FITSCH_BASE_URL: 'https://api.fitpai.co.kr:488/fitsch_api/v1/',
    FITSCH_BASE_URL: 'https://www.fitcollabo.com:8443/fitsch_api/v1/',
    HOMEBAR_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA7hwLXKQpnzFiwxsOTuv6lcyZJGULJwCM',
    // PAI_BASE_URL: 'https://api.fitpai.co.kr:488/pai_api/v1/',
    PAI_BASE_URL: 'https://www.fitcollabo.com:8443/pai_api/v1/',

    // BS_BASE_URL: 'https://api.fitpai.co.kr:488/bs_api/v1/', // 빠숍
    BS_BASE_URL: 'https://www.fitcollabo.com:8443/bs_api/v1/', // 빠숍
    HB_BASE_URL: 'https://www.homebbar.com:443/api/v1/', // 빠숍

    // TV포인트 앱
    // TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDAxzBg3noOfzQCl5ZpOK3Zt3q_k8RiISI',
    TVPOINT_BASE_URL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDCr5VKVaY99FEf7K_wD7E6G9FxCoGu4qk',

    // TV포인트 기프티콘 URL
    SKP_BASE_URL: 'https://opoc.gifticon.com/',

    // 앱카드 인증 URL
    APPCARD_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:488/APPCARD-AUTH/index.html#/',
    APPPAY_MODULE_BASE_URL: 'https://payment.tvhub.co.kr:488/APPPAY-AUTH/index.html#/',

    // 멜론 로그인 페이지 URL
    MELON_LOGIN_URL: 'https://cola.melon.com/internal/v2/auth/melon',

    // 홈빠 클라이언트 URL
    HOME_BBAR_URL: 'https://www.homebbar.com/hband/index.html',

    // v-market URL
    V_MARKET_QUIZ_URL: 'https://rapi01.helpstore.shop/mission/quiz/list',
    V_MARKET_PLACE_URL: 'https://rapi01.helpstore.shop/mission/place/list',
  },
};

export default urlMaps[FTSettings.deployMode];
