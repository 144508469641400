import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';
import $store from '@/store';
import $utils from '@/utils/tvPointcommon';

// TV페이 결제 내역 불러오기
function getTvpayList() {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/getTvpayList.tvp${getSession($store.getters.getSessionId)}`);
}

// 로그 정보 남기기
function saveLog(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/saveLog.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}
// 클릭 로그 정보 남기기
function saveContentsLog(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/contents/saveContentsLog.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 사용처별 사용 가능포인트 조회
function getUsableList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getUsableList.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 버즈빌 평균 포인트 받기
function getBuzzPoint() {
  // return axios.get('https://benefit.buzzvil.com/api/total-reward');
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getBuzzPoint.tvp${getSession($store.getters.getSessionId)}`);
}

// url로 들어온 사람 텀아이디로 가입여부 조회
function checkTermUser(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/checkTermUser.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 홈빠 베오베 상품 받아오기
function getHomebbar(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/getHomebbarBOB.tvp${getSession($store.getters.getSessionId)}`, params);
}

// transTermId : ci를 가상 termid로 변경 추후 아래 같은 내용으로 수정
function transTermId(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/transTermId.tvp${getSession($store.getters.getSessionId)}`, params);
}

// readStoreId : TV포인트 모바일은 제외
// saveData : 세션데이터 저장
// params에 loginToken값이 있으면 해당 값으로 대체하도록 수정 mjkim
function callRelayRpServer(params) {
  // params = { tvpaySubUrl: "/cert2/readStoreId.mv",....};
  let loginCheck = !$utils.isNull(params.loginCheck) ? params.loginCheck : 'false';
  $utils.printLog('loginCheck', loginCheck);
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/tvpay/reqRelay.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: loginCheck,
  });
}

function insSessionData(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/setMobileInsData.tvp${getSession($store.getters.getSessionId)}`, params);
}
function getSessionData(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/getMobileInsData.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 홈빠 상품 URL 동적 링크 설정
function createShortLinkForWeb(params) {
  return axios.postJson(`${urlConfig.HOMEBAR_BASE_URL}`, params);
}

// TVPOINT URL 동적 링크 설정
function createShortLinkForTvpoint(params) {
  return axios.postJson(`${urlConfig.TVPOINT_BASE_URL}`, params);
}

// 자체인증을 위한 사용자 정보 세션 저장
function checkUser(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/checkUser.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 화면 기준 사용자의 동의 정보 목록 호출
function getAgrmntList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/getAgrmntList.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 화면 기준 사용자의 동의 정보 호출
function getAgrment(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/getAgrment.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 화면 기준 사용자의 동의 정보 저장
function setAgrment(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/setAgrment.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 회원 알림 목록 검색
function getUserNoticeList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/getUserNoticeList.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 바로가기 리스트 가져오기
function getDirects(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/getUserBMKList.tvp${getSession($store.getters.getSessionId)}`, params);
}
function setDirect(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/setUserBMK.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getDirectsNoMember() {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/getBMKList.tvp${getSession($store.getters.getSessionId)}`);
}

// 자동전환
function manageAutoTransfer(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/manageAutoTransfer.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 자동충전
function manageAutoCharge(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/manageAutoCharge.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 포인트로만 결제
function gifticonCardPay(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/kcp/gifticonCardPay.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 포인트로만 결제
function gifticonPointPay(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/kcp/gifticonPointPay.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 결제 화면 띄우기
function createSkpOrder(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/skp/createSkpOrder.tvp${getSession($store.getters.getSessionId)}`, params);
}
// 결제 화면 띄우기
function kcpOrderApproval(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/kcp/kcpOrderApproval.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 카드 코드 조회 테스트
function wGetCardCode(params) {
  return axios.postLoginQs(`${urlConfig.RP_SERVER_URL}/cert2/wGetCardCode.mv${getSession($store.getters.getSessionId)}`, params);
}

// vod 캐시백 리스트
function getCashbackInfoList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/event/getCashbackInfoList.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getCashBack(params) {
  return axios.postLoginQs(
    `${urlConfig.FIT_BASE_URL}/event/participationReserveCashback.tvp${getSession($store.getters.getSessionId)}`,
    params,
  );
}

// 기프티콘 인증정보 가져오기
function callSkpAuth(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/skp/callSkpAuth.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 사용 배너 이미지 클릭 이벤트
function getPromotionInfo(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/contents/getPromotionInfo.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 사용 배너 추가 컨텐츠 가져오는 이벤트
function getPromotionMoreInfo(url) {
  return axios.postQs(url);
}

// 사용 배너 기본
function joinPromotion(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/mobile/joinPromotion.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 동부화재 리워드
function joinEventGetReward(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/event/joinEventGetReward.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getSessionId(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/main/getSessionId.tvp`, {
    ...params,
    loginCheck: 'false',
  });
}

function checkUserDevice(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/checkUserDevice.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function checkUserDevice_web(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/checkUserDevice_web.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function checkUserExist(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/checkUserExist.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function login(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/auth/login.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function logout() {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/auth/logout.tvp${getSession($store.getters.getSessionId)}`);
}

function modifyPwd(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/modifyPassword.tvp${getSession($store.getters.getSessionId)}`, params);
}

function sendSelfAuth(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/sendAuthCode.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function certifyUserAuth(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/certifyUserRequest.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

//KCB - 기존회원 아닐경우
function certifyUserResult(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/certifyUserResult.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}
//기존 회원 - 자체인증
function verifySelfAuth(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/relay/verifyAuthCode.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function resetPassword(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/resetPassword.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function addUser(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/addUser.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function deleteFitUser(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/user/deleteFitUser.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'true',
  });
}

function addTVUser(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/addTVUser.tvp${getSession($store.getters.getSessionId)}`, params);
}

function addUserFamily(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/addUserFamily.tvp${getSession($store.getters.getSessionId)}`, params);
}

function sendAgreementResult(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/sendAgreementResult.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getNoticeList(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/main/getNoticeList.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function getBannerInfoList(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/contents/getBannerInfoList.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function getFaqList(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/faq/getFaqList.tvp`, {
    ...params,
    loginCheck: 'false',
  });
}

function tvhubTerms(params) {
  return axios.get(`${urlConfig.FIT_BASE_URL}/WEB/text/${params}`);
}

function getUser(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/getUser.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getCelNoUser(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/getCelNoUser.tvp${getSession($store.getters.getSessionId)}`, params);
}

function checkUserPwd(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/auth/checkUserPwd.tvp${getSession($store.getters.getSessionId)}`, params);
}

// TMO
function verity(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tmo/verify.tvp${getSession($store.getters.getSessionId)}`, params);
}

function approve(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tmo/approve.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getPresentablePoint(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPresentablePoint.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 기프티콘 포인트 조회
function getUserUsablePoint(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getUserUsablePoint.tvp${getSession($store.getters.getSessionId)}`, params);
}

// // 선물하기 포인트 조회
// function getPresentablePoint(params) {
//   return axios.postQs(`${urlConfig.FIT_BASE_URL}/point/getPresentablePoint.tvp${getSession($store().getters.getSessionId)}`, params);
// }

function getMyCoupon(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/voucher/getMyCoupon.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getSendCouponHistory(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/coupon/getSendCouponHistory.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getCouponList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/coupon/getCouponPriceList.tvp${getSession($store.getters.getSessionId)}`, params);
}

function sendCoupon(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/coupon/sendCoupon.tvp${getSession($store.getters.getSessionId)}`, params);
}

function givePointPresent(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/givePointPresent.tvp${getSession($store.getters.getSessionId)}`, params);
}

function registerCoupon(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/coupon/registerCoupon.tvp${getSession($store.getters.getSessionId)}`, params);
}

function keypadInit() {
  return axios.post('http://localhost:10090/z_random_keypad_test/keypad/keypadInit.mvp');
}

function pwdDecrypt(params) {
  // 복호화
  return axios.post(
    'http://localhost:10090/z_random_keypad_test/keypad/pwdDecrypt.mvp;jsessionid=' + params.jsessionId,
    Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&'),
  );
}

function getPointSum(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPartnerPointSum.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getPartnerShipList(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/point/getPartnershipList.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

function getPartnerShipPoint(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPartnerPointList.tvp${getSession($store.getters.getSessionId)}`, params);
}
function getCouponPoint(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPartnershipPoint.tvp${getSession($store.getters.getSessionId)}`, params);
}

function transferPartnerShipPoint(params) {
  return axios.postLoginQs(
    `${urlConfig.FIT_BASE_URL}/point/transferPartnershipPoint.tvp${getSession($store.getters.getSessionId)}`,
    params,
  );
}

function getTransAgree(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPointTransAgreement.tvp${getSession($store.getters.getSessionId)}`, params);
}

function setTransAgree(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/setPointTransAgreement.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getPointHistory(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/getPointHistory.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getExpPointHistory(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/getExpPointHistory.tvp${getSession($store.getters.getSessionId)}`, params);
}

function registerQna(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/qna/registerQna.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getQnaList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/qna/getQnaList.tvp${getSession($store.getters.getSessionId)}`, params);
}

function qnaTypeList() {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/qna/getQnaType.tvp${getSession($store.getters.getSessionId)}`);
}

function getSession(jsession) {
  return ';jsessionid=' + jsession;
}

function getFamilyList() {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/getFamilyList.tvp${getSession($store.getters.getSessionId)}`);
}

function setFamilyInfo(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/setFamilyInfo.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getPointSumUserNo(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getSum.tvp${getSession($store.getters.getSessionId)}`, params);
}

function makeTid(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/charge/getChargePointTid.tvp${getSession($store.getters.getSessionId)}`, params);
}

function stealFamilyPoint(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getFamilyPoint.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getFeeInfo(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPartnerFeeInfo.tvp${getSession($store.getters.getSessionId)}`, params);
}

function readNoti(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/setUserNotice.tvp${getSession($store.getters.getSessionId)}`, params);
}

function delNoti(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/relay/delUserNotice.tvp${getSession($store.getters.getSessionId)}`, params);
}

function checkPayment(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/checkPayment.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getEventInfo(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getEventInfo.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 앱카드 인증 거래번호 요청
function reqTransNo(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/appcard/reqTransNo.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 앱카드 인증 결과 조회
function reqAuthData(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/appcard/reqAuthData.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 앱카드 인증 성공 후 비밀번호 설정
function appCardSetPwd(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/setMobile.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 휴대폰번호 변경
function setUserCel(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/user/setUserCel.tvp${getSession($store.getters.getSessionId)}`, params);
}

//알림설정 (구독 채널 알림- 핏)
function setFitAlarmData(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/setAlarmData.tvp`, params);
}
// 자동로그인 설정
function setAutoLogin(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/auth/setAutoLogin.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 포인트 전환설정
function setTransferYn(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/auth/setTransferYn.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 포인트 전환설정
function autoLogin(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/auth/autoLogin.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 제휴사 포인트 조회
function getPartnerPoint(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/point/getPartnerPoint.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 개인정보처리방침 목록 조회
function getTermsOfUserList(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/tvpay/getTermsOfUseList.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 개인정보처리방침 조회
function getTermsOfUse(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/tvpay/getTermsOfUse.tvp${getSession($store.getters.getSessionId)}`, {
    ...params,
    loginCheck: 'false',
  });
}

// 3. export
export {
  keypadInit,
  pwdDecrypt,
  getSessionId,
  checkUserDevice,
  login,
  logout,
  modifyPwd,
  sendSelfAuth,
  certifyUserAuth,
  certifyUserResult,
  verifySelfAuth,
  resetPassword,
  addUser,
  deleteFitUser,
  addUserFamily,
  sendAgreementResult,
  getNoticeList,
  getBannerInfoList,
  checkUserExist,
  checkUserDevice_web,
  getFaqList,
  tvhubTerms,
  getUser,
  getCelNoUser,
  verity,
  checkUserPwd,
  approve,
  getPresentablePoint,
  getMyCoupon,
  getSendCouponHistory,
  getCouponList,
  sendCoupon,
  givePointPresent,
  registerCoupon,
  getPointSum,
  getPointSumUserNo,
  getPartnerShipList,
  getPointHistory,
  getExpPointHistory,
  registerQna,
  getQnaList,
  callSkpAuth,
  getPromotionInfo,
  getPromotionMoreInfo,
  joinPromotion,
  joinEventGetReward,
  getPartnerShipPoint,
  transferPartnerShipPoint,
  getCashbackInfoList,
  wGetCardCode,
  getCouponPoint,
  getCashBack,
  getTransAgree,
  setTransAgree,
  qnaTypeList,
  createSkpOrder,
  gifticonPointPay,
  kcpOrderApproval,
  gifticonCardPay,
  manageAutoTransfer,
  getDirects,
  setDirect,
  getDirectsNoMember,
  getUserNoticeList,
  getAgrmntList,
  getFamilyList,
  setFamilyInfo,
  createShortLinkForWeb,
  callRelayRpServer,
  transTermId,
  getHomebbar,
  setAgrment,
  getAgrment,
  manageAutoCharge,
  makeTid,
  stealFamilyPoint,
  createShortLinkForTvpoint,
  checkTermUser,
  getUserUsablePoint,
  addTVUser,
  getBuzzPoint,
  getFeeInfo,
  readNoti,
  delNoti,
  checkPayment,
  getUsableList,
  saveLog,
  getTvpayList,
  saveContentsLog,
  checkUser,
  getEventInfo,
  reqTransNo,
  reqAuthData,
  appCardSetPwd,
  insSessionData,
  getSessionData,
  setUserCel,
  setFitAlarmData,
  setAutoLogin,
  setTransferYn,
  autoLogin,
  getPartnerPoint,
  getTermsOfUserList,
  getTermsOfUse,
};
