import $store from '@/store';
import $router from '@/router';
import $commons from '@/utils/commons';
import $utils from '@/utils/tvPointcommon.js';
import dateUtil from '@/utils/dateProperty';
import bus from '@/utils/bus';
import _ from 'lodash';
import FTInitSetUp from '@/modules/ft-init-setup';

// jhkim: 추가
import Logger from '@/modules/ft-logger';
import { Key, Service } from '@/modules/ft-constants';
import FTTemplateDispatcher from '@/modules/ft-template-dispatcher.js';
import $bridge from '@/utils/FTBridgeShared';
import Cache from '@/modules/ft-cache';

export const fitMixin = {
  methods: {
    async sendEventLink(value, linkType, path) {
      $utils.printLog('sendEventLink value : ', value);
      $utils.printLog('sendEventLink linkType : ', linkType);
      $utils.printLog('sendEventLink path : ', path);
      //홈 화면에서 혜택 더보기 했을 경우 팝업 닫아주기
      if (!$commons.isNullObject($store.state.common.componentPopup)) $store.commit('setComponentPopup', {});
      switch (linkType) {
        case 1:
          break;
        case 2: {
          //내부 페이지 이동
          const depth = [...$store.state.fit.pageDepth1, ...$store.state.fit.pageDepth2];
          const data = depth.find(data => data.id === value);

          if (!$commons.isNull(data) && data.menu_type === 1 && data.depth === 1) {
            //initPage 내에서 슬라이드 할 경우
            if (this.$route.name !== 'initPage') {
              $router.push('/initPage');
              data.idx === 0 ? this.$store.commit('home', true) : this.$store.commit('home', false);
            }
            $store.commit('setMainIdx', data.idx);
            bus.$emit('fitSetPageLink', Service.FIT, Number(data.idx));
          } else if (!$commons.isNull(data) && data.menu_type === 3) {
            if (data.uri === '/bar/pages/orders') {
              $router.push({ name: 'OrderList', query: { id: data.id, uri: data.uri, depth: data.depth, title: data.title } });
            } else if (data.uri === '/bar/pages/stores') {
              $router.push('/bbarshop/storeAll');
            } else {
              // initPage에서 bbarShop으로 가는 경우
              await $router.push('/bbarshop');
              $store.commit('setMainIdx', data.idx);
              await bus.$emit('bsSetPageLink', Service.BSHOP, Number(data.idx));
            }
          } else {
            //2nd depth 화면으로 이동할 경우
            if (data.uri === '/fit/pages/exchange-config') {
              $router.push('/user/transferSetting');
            } else {
              $router.push(data.uri);
              $store.commit('home', false);
            }
            // $router.push(data.uri);
            // $store.commit('home', false);
          }
          break;
        }
        case 3: {
          //이벤트 페이지 이동
          $router.push({ name: 'EventDetailView', query: { id: `${value}` } });
          break;
        }
        case 4:
          $router.push({ name: 'ExhibitionDetail', query: { id: `${value}` } });
          break;
        case 5:
          if (value === 'http://cpms.tvpoint.co.kr/WEB/promotion/mToon/webtoon.tvp') {
            value +=
              '?deviceId=' +
              $store.state.tvpoint.userCel +
              '&userNo=' +
              $store.state.tvpoint.userNo +
              '&termType=' +
              $store.state.tvpoint.termType;
            if ($store.state.common.deviceType === 3) {
              window.open(value);
            } else {
              location.href = value;
            }
          } else if (
            value === 'http://m.w-shopping.co.kr/tv-hub/channel-gate/MC12?' ||
            value === 'https://m.kshop.co.kr/?with_code=000039&'
          ) {
            value += 'userNo=' + this.$store.state.tvpoint.userNo;
            if ($store.state.common.deviceType === 3) {
              window.open(value);
            } else {
              location.href = value;
            }
          } else {
            // location.href = value;
            window.open(value, '_blank');
            this.informEvent(value);
          }
          break;
        case 6:
          // 상품 상세
          $store.state.fit.clickLink = true;
          $router.push({ name: 'ProductDetail', query: { id: `${value}`, shop_type: 2, uri: path } });
          break;
        case 7:
          break;
        case 8:
          // 스토어
          $router.push({ name: 'StoreHome', query: { id: `${value}` } });
          break;
        case 9:
          break;
        case 10:
          break;
      }
    },

    async getPageData(serviceType, menuIdx, path) {
      // jhkim: vue3: router 참조 변경
      $utils.printLog('fit mix: ', $router.currentRoute.value.fullPath);

      // jhkim: vue3: router 참조 변경
      if ((!path && $router.currentRoute.value.fullPath !== '/bbarshop') || (path && path === '/initPage')) {
        const pageData = $store.state.fit.pageDepth1.filter(data => data.menu_type == serviceType);
        const pageId = pageData[menuIdx].id;

        // jhkim: 핏콜라보 서비스중 home, pointshop, fitcall, event는 동적 화면 구성 페이지로 분기
        const uriList = ['/fit/pages/home', '/fit/pages/pointshop', '/fit/pages/fitcall', '/fit/pages/event'];
        if (uriList.includes(pageData[menuIdx].uri)) {
          return this.getBSShopPage(serviceType, menuIdx);
        }
        // bbarshop?idx로 들어오는 uri
        if (pageData[menuIdx].uri.includes('/bar/pages')) {
          return this.getBSShopPage(serviceType, menuIdx);
        }

        const pageMenus = serviceType == Service.FIT ? $store.state.fit.fitMenus : $store.state.bbarshop.bbarshopMenus;
        const pageMenu = pageMenus[menuIdx];

        // jhkim: 통계 수집. 모듈 호출로 수정
        //FTTemplateDispatcher.dispatchInformEvent(pageData[menuIdx]);

        const res = await FTTemplateDispatcher.dispatchTemplateGroupList(pageMenu);

        // const cutData = res.group_templates.filter(group => group.order < 4); 핏콜에서 작동오류로 수정 espark
        const cutData = res.group_templates.filter(group => group.component_templates.length > 0);

        // res.group_templates.forEach(group => {
        await cutData.forEach(group => {
          group.component_templates.filter(async component => {
            let params = {
              pageId: $store.state.fit.pageDepth1[menuIdx].id,
              componentId: component.id,
              groupId: group.id,
            };

            //한 화면에 배너 정보가 많아서 나누기 위해 적용
            switch (component.type) {
              case 14: {
                // 롤링배너
                params.groupType = 'banners';
                let data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setRollingBannerList', data);
                // jhkim: 2023.07.17: 화면 잔상 처리하기 위해 각 페이지별로 데이터 저장. setRollingBannerList는 어디서 사용 할 지 몰라 삭제 못함.
                pageMenu.rollingBanner = data;
                break;
              }
              case 15: {
                // 배너 스크롤
                params.groupType = 'banners';
                let data = await $store.dispatch('fitBasicApi', params);

                if (component.uri === '/banner-scroll' && data.banner_components) {
                  await $store.commit('setScrollBannerList', data);
                } else if (component.uri === '/banner-scroll/olleh') {
                  await $store.commit('setVodPlatformScrollBannerList', data);
                } else if (component.uri === '/banner-scroll/event') {
                  await $store.commit('setEventScrollBannerList', data);
                }
                break;
              }
              case 16: {
                params.groupType = 'banners';
                let data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setGridBannerList', data);
                break;
              }
              case 17: {
                params.groupType = 'vods';
                let data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setComponentVodList', data);
                await $store.commit('setComponentVodListParams', params);
                break;
              }
              case 18: {
                params.groupType = 'benefits';
                let data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setComponentBenefitList', data);
                break;
              }
              case 19: {
                params.groupType = 'banners';
                let data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setComponentBenefitListHome', data);
                break;
              }
            }
          });
        });

        // jhkim: Page 번호 비교 uri로 변경
        switch (pageData[menuIdx].uri) {
          case '/fit/pages/point':
            if ($utils.isNull($utils.getCookie('buzvill'))) {
              $utils.printLog('$utils.getCookie(buzvill)', $utils.getCookie('buzvill'));
              $store.commit('setBuzvillToolTip', 'Y');
            }
            await $store.dispatch('fitPointUse', 1);
            break;
          case '/fit/pages/pai': {
            // 파이
            const params = {
              order_type: 1,
              info_type: 'all',
              category: 'all',
            };

            const data = await $store.dispatch('paiBenefitList', params);
            let benefitList = data.contents;

            if ($store.state.tvpoint.isLogin) {
              const benefit = await $store.dispatch('contentsCount');
              const benefitCount = benefit.counts ?? [];
              const keyword = await $store.dispatch('subscriptionCount');
              const keyWordCount = keyword.counts ?? [];

              const countList = [...benefitCount, ...keyWordCount];
              $commons.printLog('fitMixin benefitCountList', countList);

              if (!$commons.isNull(benefitList)) {
                for (let i = 0; i < benefitList.length; i++) {
                  for (let j = 0; j < countList.length; j++) {
                    if (countList[j].channel_id && benefitList[i].channel_id === countList[j].channel_id) {
                      benefitList[i].count = countList[j].count;
                    }
                    if (countList[j].keyword && benefitList[i].keyword === countList[j].keyword) {
                      benefitList[i].count = countList[j].count;
                    }
                  }
                }
                //1. ch_type SUB, null 인 것 저장
                const nullSubList = benefitList.filter(item => item.ch_type !== 'TOP');
                //2. ch_type TOP 인 것 저장
                let topList = benefitList.filter(item => item.ch_type === 'TOP');

                if (!$commons.isNull(topList)) {
                  for (let i = 0; i < topList.length; i++) {
                    for (let j = nullSubList.length - 1; j >= 0; j--) {
                      if (nullSubList[j].channel_id === topList[i].channel_id) {
                        // this.$set(nullSubList[j], 'channel_id', 'top');
                        nullSubList[j].ch_type = 'sub';
                      }
                    }
                  }
                  $commons.printLog(nullSubList);
                  //3. TOP 리스트 날짜에 따라 정렬
                  topList = topList.sort((a, b) => new Date(a.subs_date) - new Date(b.subs_date));
                  //4. TOP 리스트 상단 배치 위해 합침
                  benefitList = [...topList, ...nullSubList];
                }
                $store.commit('benefitList', benefitList);
              } else {
                $store.commit('benefitList', []);
              }

              // await $store.dispatch('subscriptionCount');
              const { data } = await $store.dispatch('subChannelList');
              await $store.commit('subChannelList', data.subs_channels);

              await $store.dispatch('storedBenefitList', false);
            } else if (benefitList) {
              // 비로그인 시 혜택목록
              if (!localStorage.getItem('benefitList')) {
                for (let i = 0; i < benefitList.length; i++) {
                  benefitList[i].read = true;
                }
              } else {
                //local에 저장된 목록이 있을 경우, 로컬에 저장해놓은 데이터와 비교해서 메시지 내용 다르면 새 혜택으로 간주 - (전체읽음 시와 채널소식방 진입 시 benefitList 로컬에 저장)
                const localBenefit = JSON.parse(localStorage.getItem('benefitList'));
                for (let i = 0; i < benefitList.length; i++) {
                  if (!benefitList[i].read) {
                    benefitList[i].read = true;
                  }
                  for (let j = localBenefit.length - 1; j >= 0; j--) {
                    if (localBenefit[j].channel_id === benefitList[i].channel_id) {
                      benefitList[i].read = localBenefit[j].read;
                      if (localBenefit[j].id !== benefitList[i].id) {
                        benefitList[i].read = true;
                      }
                    }
                  }
                }
                // local에 저장
                localStorage.setItem('benefitList', JSON.stringify(benefitList));
              }
              $store.commit('benefitList', benefitList);
            }
            // $store.state.pai.filter = {};
            // await $store.dispatch('getFilter');
            $store.dispatch('getCodeCategoryMain');
            $store.dispatch('getCodeContentInfo');

            //파이 혜택 모아보기 물으표에서 이동 하는 이벤트 찾기 위해
            const paiCollectEvent = await $store.dispatch('eventList');
            const resData = paiCollectEvent.event_pages.filter(event => event.component_name === 'PAI 서비스 안내');
            // const resData = paiCollectEvent ? paiCollectEvent.event_pages.filter(event => event.component_name === 'PAI 서비스 안내') : [];
            $store.commit('setPaiCollectEvent', resData);
            // jhkim: 2023.07.17: 로딩 제거
            // bus.$emit('end:loading');
            break;
          }
          case '/fit/pages/pay': //tv페이
            await $store.dispatch('fitPointUse', 2);
            if ($store.state.tvpoint.isLogin) {
              const currentMonth = dateUtil.fetchDate('year-month');
              const threeMonthsAgo = dateUtil.dateHistory(3, 'month');

              const params = {
                startDate: threeMonthsAgo,
                endDate: currentMonth,
                type: '',
                startIdx: '1',
                endIdx: '3',
              };

              const data = await $store.dispatch('setMobileCardList');
              if (data.listCount === 0) {
                await this.$store.dispatch('setTVCardListCount');
              }

              await $store.dispatch('setPaymentHistory', params);

              // jhkim: 2023.07.17: 로딩 제거
              // bus.$emit('end:loading');
            }
            break;
        }
        // jhkim: 2023.07.17: 화면 잔상 제거
        return pageMenu;
        // jhkim: 추가
        // return pageData[menuIdx];
        // jhkim: vue3: router 참조 변경
      } else if ((!path && $router.currentRoute.value.fullPath === '/bbarshop') || (path && path === '/bbarshop')) {
        return this.getBSShopPage(serviceType, menuIdx);
      }
    },
    async getBSShopPage(serviceType, menuIdx) {
      const pageData = $store.state.fit.pageDepth1.filter(data => data.menu_type == serviceType);
      const pageId = pageData[menuIdx].id;
      const pageMenus = serviceType == Service.FIT ? $store.state.fit.fitMenus : $store.state.bbarshop.bbarshopMenus;
      const pageMenu = pageMenus[menuIdx];

      // jhkim: 통계 수집
      //FTTemplateDispatcher.dispatchInformEvent(pageData[menuIdx]);

      // jhkim: 템플릿 정보 (컴포넌트, 상품 목록, 카테고리 목록)
      const templateData = await FTTemplateDispatcher.dispatchTemplateData(pageMenu);

      // 메뉴 구조
      // pages[idx] = {
      // 	idx: idx,
      // 	title: pageData[idx].title,
      // 	order: pageData[idx].order,
      // 	staticTemplateList: [], // 정적
      // 	dynamicTemplateList: [], // 동적
      // };
      // const pages = serviceType == 1 ? $store.state.fit.fitMenus : $store.state.bbarshop.bbarshopMenus;
      pageMenu.staticTemplateList = templateData.staticTemplateList; // 정적 컴포넌트
      pageMenu.dynamicTemplateList = templateData.dynamicTemplateList; // 동적 컴포넌트

      $store.commit('bsExhibitionCategory', templateData.categoryList);

      Logger.info('staticTemplateList: %s', JSON.stringify(pageMenu.staticTemplateList));
      Logger.info('dynamicTemplateList: %s', JSON.stringify(pageMenu.dynamicTemplateList));
      Logger.info('request completed!!!');

      return pageMenu;
    },

    async channelSetup(id) {
      if ($store.state.tvpoint.isLogin) {
        $store.dispatch('subChannelState', id);

        // 내 포인트 조회 했을 때 회원/비회원 나눈 리스트를 합쳐서 filter 하기 위해
        const list = [...$store.state.pai.fetchHiddenAsset, ...$store.state.pai.nonMemberHiddenAsset];
        let selected_store;
        if (!$commons.isNull(list)) {
          selected_store = list.filter(data => data.id === id);
        } else {
          //숨겨진 자산 조회를 하지 않아서 데이터가 없을 경우 api 통신으로 자산 조회 목록 가져오기
          //전체 채널 목록 조회에 tvpoint_id 넣어 준다고 하면 paiChannelList 에서 뽑아 오면 됨.
          const res = await $store.dispatch('fetchHiddenAsset');
          $store.commit('fetchHiddenAsset', res.channels);
          selected_store = res.channels.filter(data => data.id === id);
        }

        //티비포인트 이외 제휴사 포인트 조회에서 리스트에 합쳐주기. 티비포인트는 통신하면 에러남
        if (
          selected_store[0]?.tvpoint_id !== 'TVPOINT' &&
          !$commons.isNull(selected_store[0]?.tvpoint_id) &&
          selected_store[0]?.tvpoint_id !== 'undefined'
        ) {
          const params = {
            tvpoint_id: selected_store[0].tvpoint_id,
            fit_token: await $bridge.shared().stringValue('loginToken'),
            phone_no: await $bridge.shared().stringValue('userPhone'),
          };
          //해당 아이디 제휴사 포인트 조회
          const data = await $store.dispatch('fetchAssociatePoint', params);
          $store.commit('saveAssociatePoint', data);

          //채널 홈 회원 로그인/비회원 노출 여부 판단 저장 - 현재 제휴사만 노출 되는 상항 - 비회원 일 경우에 회원가입을 할 수 없는 관계로 일단 noChannel 로 화면 구현
          // 회원 가입 가능 할시에는 아래 주석 처리된 소스 사용.
          data.user_status === 'Y' ? $store.commit('channelAccount', 'ChannelLoggedIn') : $store.commit('channelAccount', 'noChannel');
          // data.user_status === 'Y' ? $store.commit('channelAccount', 'ChannelLoggedIn') : $store.commit('channelAccount', 'nonMember');
        } else if ($commons.isNull(selected_store[0]?.tvpoint_id)) {
          //티비포인트 제휴사가 아닌 가맴점 등록 되었을 경우 아직 계정 로그인 판던 없는 관계로 로그인/비로그인/휴먼계정 상태 미노출 하기 위해
          // 추후 제휴사 로그인 여부 정리 되면 필요없음
          // storeInfo/index <component> 에 있는 v-if 도 제거 해주면 됨.
          $store.commit('channelAccount', 'noChannel');
        } else {
          //티비포인트는 api 조회없이 로그인 됬을 경우 채널 홈 회원 유무 저장 및 포인트 저장( 동일한 화면이라 데이터 가져오는 형식 통일하기 위해)
          const point = {
            point: $store.state.tvpoint.pointBalance,
          };
          $store.commit('channelAccount', 'ChannelLoggedIn');
          $store.commit('saveAssociatePoint', point);
        }
      }
    },

    async fetchPoint() {
      const res = await $store.dispatch('fetchHiddenAsset');

      let list = [];
      let topLen = 0;
      // bus.$emit('start:loading');
      for (let channel of res.channels) {
        const params = {
          tvpoint_id: channel.tvpoint_id,
          fit_token: await $bridge.shared().stringValue('loginToken'),
          phone_no: await $bridge.shared().stringValue('userPhone'),
        };

        let point;
        let rank = {};
        //티비포인트 이외 제휴사 포인트 조회에서 리스트에 합쳐주기. 티비포인트는 통신하면 에러남
        if (!$commons.isNull(channel.tvpoint_id) && channel.tvpoint_id !== 'undefined') {
          point = await $store.dispatch('fetchAssociatePoint', params);
        }

        //sort order 클라에서 처리 해야 해서 rank라는 데이터 삽입
        // sort Order 기준 TOP 이 있을 경우 최상위 그다음이 포인트 금액 높은 순
        // TOP 일 경우 0 이고 나머지는 1
        if (channel.ch_type === null || channel.ch_type === 'SUB' || channel.ch_type === 'HID') {
          rank.rank = 1;
        } else if (channel.ch_type === 'TOP') {
          rank.rank = 0;
          topLen++;
        }

        channel = { ...channel, ...point, ...rank };
        list = [...list, { ...channel }];
      }

      // 순서 나열하기 TOP > point 높은 순 > 나머지
      list = _.orderBy(list, ['rank', 'point'], ['asc', 'desc']);

      // DB 상 ch_type에 한 테이블에 있어서 조회 했을경우 테이블 전체를 내려줘 중복 값이 있다고 함
      // 즉 한 아이디에 TOP 도 있고 SUB 도 있고 있을 수 있기 때문에 중복 제거를 해야지 만 하나만 존재 하게 됨.
      list = _.uniqBy(list, 'id');

      // 제휴사 회원 여부 판단해서 나누기 user_status = y 일 경우 회워 n 일경우 비회원, tvpoint 는 로그인만 되어 있으면 회원이기 때문에
      const member = list.filter(data => data.user_status === 'Y');
      const nonMember = list.filter(data => data.user_status === 'N');

      $store.commit('hiddenAssetTopLength', topLen); // top 5 개까지만 제한하기 위해 따로 저장
      $store.commit('fetchHiddenAsset', member); // 회원일 경우만
      $store.commit('nonMemberHiddenAsset', nonMember); // 비회원일 경우만만
      bus.$emit('end:loading');
    },

    async paiLogin() {
      $commons.printLog('☆ fitMixin.js paiLogin');
      const deferred = $utils.defer();

      const payload = {
        device_id: !$commons.isNull($store.state.common.saveDeviceId) ? $store.state.common.saveDeviceId : null,
        device_type: $store.state.common.deviceType,
        fit_token: await $bridge.shared().stringValue('loginToken'),
      };

      $commons.printLog('☆ paiLogin $store.state.common.token : ', $store.state.common.token);

      let fcmToken = $store.state.common.token;

      // if ($store.state.common.deviceType === 1) {
      // const versionName = await $bridge.shared().versionName();
      // const curVersion = versionName.split('.');
      // const appVersion = parseInt(`${curVersion[0]}${curVersion[1]}${curVersion[2]}`);
      // $commons.printLog('☆ paiLogin curVersion : ', curVersion);
      // $commons.printLog('☆ paiLogin appVersion : ', appVersion);

      if ($commons.isNull(fcmToken)) {
        fcmToken = await $bridge.shared().stringValue('requestToken');
        await this.$store.commit('saveToken', fcmToken);
        $commons.printLog('☆ paiLogin  requestToken fcmToken : ', fcmToken);
        $commons.printLog('☆ paiLogin  $store.state.common.token  : ', $store.state.common.token);
      }
      payload.fcm_token = fcmToken;
      // }

      $commons.printLog('☆ paiLogin device_id : ', payload.device_id);
      $commons.printLog('☆ paiLogin device_type : ', payload.device_type);
      $commons.printLog('☆ paiLogin fit_token : ', payload.fit_token);
      $commons.printLog('☆ paiLogin fcm_token : ', payload.fcm_token);

      const data = await $store.dispatch('paiLogin', payload);

      if (!this.$commons.isNull(data.access_token)) {
        this.$utils.printLog('paiLogin success');
        await $store.dispatch('alarmCount');
        if ($store.state.common.deviceType === 1 || $store.state.common.deviceType === 3) await this.subscribeFCM();
        deferred.resolve();
      } else {
        this.$utils.printLog('paiLogin fail');
        deferred.resolve();
      }

      // data.then(async () => {
      // 	//구독 push 문제를 해결 하기 위해 user의 구독 채널을 조회
      // 	// 조회 결과를 FCM에 다시 보낸다. 그래야지 로그아웃 했다가 다시 로그인 했을 경우 해당 구독 채널 push를 받을 수 있다.
      // 	if ($store.state.common.deviceType === 1) await this.subscribeFCM();
      // 	deferred.resolve();
      // });

      return deferred.promise;
    },

    async subscribeFCM() {
      let data;
      if ($store.state.tvpoint.isLogin) {
        data = await $store.dispatch('fetchSubscribeList');
      }
      // const res = await $store.dispatch('fcmSubscribeList');

      $commons.printLog('☆ fcm subscribe data : ', JSON.stringify(data));
      $commons.printLog('☆ fcm subscribe data : ', $commons.isNull(data));
      if (!$commons.isNull(data)) {
        //0110000 는 비회원에게 내겨 오는 푸쉬이다 그래서 fcm에서 해지 시켜 버리기
        const params = {
          to: '/topics/01100000',
          registration_tokens: [$store.state.common.token],
        };
        await $store.dispatch('fcmUnSubscribe', params);

        for (let topic of data.topics) {
          const payload = {
            to: `/topics/${topic}`,
            registration_tokens: [$store.state.common.token],
          };

          $commons.printLog('===================================================');
          $commons.printLog('☆ fcm subscribe payload : ', JSON.stringify(payload));
          await $store.dispatch('fcmSubscribe', payload);
        }
      } else {
        //첫 진입시 푸쉬 동의 할 경우 비회원 혜택 보내기 위해 fcm 등록
        const params = {
          to: '/topics/01100000',
          registration_tokens: [this.$store.state.common.token],
        };
        await this.$store.dispatch('fcmSubscribe', params);
      }
    },

    // 빠숍 링크 타입 별 페이지 이동. jslim
    async sendBbarShopLink(value, linkType, linkValue, path, uri) {
      //홈 화면에서 혜택 더보기 했을 경우 팝업 닫아주기
      if (!$commons.isNullObject($store.state.common.componentPopup)) $store.commit('setComponentPopup', {});

      $utils.printLog('sendBbarShopLink value', value);
      $utils.printLog('sendBbarShopLink value order_type', value.order_type);
      $utils.printLog('sendBbarShopLink linkType', linkType);
      $utils.printLog('sendBbarShopLink path', path);
      $utils.printLog('sendBbarShopLink uri', uri);

      let id =
        ($store.state.bbarshop.recntViewPath === true && $store.state.tvpoint.isLogin) ||
        ($store.state.common.toPath === '/bbarshop/zzim' && $store.state.tvpoint.isLogin)
          ? value.product_id
          : value.id;

      if (value.type === 1 || value.type === 2) {
        if ($store.state.tvpoint.isLogin) {
          $store.dispatch('recentViewRegister', {
            recent_product: {
              // product_id: value.id,
              product_id: id,
            },
          });
        } else {
          let recentViewRegisters = JSON.parse(localStorage.getItem('recentViewRegisters') || '[]');
          if (recentViewRegisters.indexOf(value.id) === -1) {
            recentViewRegisters.push(value.id);
          } else {
            recentViewRegisters = recentViewRegisters.filter(id => id !== value.id);
            recentViewRegisters.push(value.id);
          }
          localStorage.setItem('recentViewRegisters', JSON.stringify(recentViewRegisters));
        }
        linkType = 5;
        linkValue = value.m_url ? value.m_url : value.url;
        if (value.order_type !== 2) {
          this.trade(value, path);
        }
      }

      // 빠숍 인앱 결제 상품 시 상품 상세 페이지 이동 ( 편성 어드민에서 탭 메뉴마다 linkType 값이 다 다름)jslim
      if (value.order_type === 2) {
        // jhkim: vue3: router 참조 변경
        $store.state.fit.clickLink = false;
        $router.push({ name: 'ProductDetail', query: { id: `${id}`, type: uri } });
        return;
      }

      switch (linkType) {
        case 1:
          break;
        case 2: {
          //내부 페이지 이동
          const depth = [...$store.state.fit.pageDepth1, ...$store.state.fit.pageDepth2];
          const data = depth.find(data => data.id === value);
          if (!$commons.isNull(data) && data.menu_type === 1) {
            //initPage 내에서 슬라이드 할 경우
            if (this.$route.name === 'bbarshop' || this.$route.fullPath === '/menu') {
              // jhkim: vue3: router 참조 방식 변경
              await $router.push('/initPage');
            }
            $store.commit('setMainIdx', data.idx);
            await bus.$emit('fitSetPageLink', Service.FIT, Number(data.idx));
          } else if (!$commons.isNull(data) && data.menu_type === 3) {
            if (this.$route.name === 'initPage' || this.$route.fullPath === '/menu') {
              //initPage 내에서 빠숍으로 이동할 경우 espark
              // jhkim: vue3: router 참조 방식 변경
              await $router.push('/bbarshop');
            }
            $store.commit('setMainIdx', data.idx);
            await bus.$emit('bsSetPageLink', Service.BSHOP, Number(data.idx));
          } else {
            if (data.uri === '/bar/pages/home') {
              // jhkim: vue3: router 참조 방식 변경
              $router.push('/bbarshop');
            } else {
              // jhkim: vue3: router 참조 방식 변경
              $router.push(data.uri);
            }
          }
          break;
        }
        case 3: {
          // 이벤트
          // jhkim: vue3: router 참조 방식 변경
          if ($router.currentRoute.value.fullPath === '/bbarshop' && $store.state.common.mainIdx === 3) {
            $router.push({ name: 'FitcolDetailView', query: { id: `${linkValue}` } });
          } else {
            $router.push({ name: 'EventDetailView', query: { id: `${linkValue}`, uri: uri } });
          }
          break;
        }
        case 4: {
          // 기획전
          // jhkim: vue3: router 참조 방식 변경
          $router.push({ name: 'ExhibitionDetail', query: { id: `${linkValue ? linkValue : value}` } });
          break;
        }
        case 5: {
          // 외부 페이지
          // location.href = linkValue;
          // if (this.$store.state.common.deviceType === 1 || this.$store.state.common.deviceType === 3) {
          // 	window.open(linkValue);
          // } else {
          // 	location.href = linkValue;
          // }
          //-----
          // window.open(linkValue, '_blank');
          // this.informEvent(linkValue);
          if (
            $store.state.tvpoint.isLogin === false &&
            $utils.getCookie('shopPointPopup') !== 'N' &&
            $store.state.orderPayment.shopContinue === false &&
            value.order_type === 1 &&
            value.reward !== 0
          ) {
            this.$store.commit('slideShow', {
              slideShow: true,
              slideType: 'ShopPointInfo',
              slideContent: { value },
            });
          } else {
            $store.commit('setShopContinue', false);
            window.open(linkValue, '_blank');
            this.informEvent(linkValue);
          }

          // if (
          // 	$store.state.tvpoint.isLogin === false &&
          // 	$utils.getCookie('shopPointPopup') !== 'N' &&
          // 	$store.state.orderPayment.shopContinue === false &&
          // 	value.order_type === 1 &&
          // 	value.reward !== 0
          // ) {
          // 	this.$store.commit('slideShow', {
          // 		slideShow: true,
          // 		slideType: 'ShopPointInfo',
          // 		slideContent: { value },
          // 	});
          // } else {
          // 	$store.commit('setShopContinue', false);
          // 	window.open(linkValue, '_blank');
          // 	this.informEvent(linkValue);
          // }

          break;
        }
        case 6:
          // 상품 상세
          // $router.push({ name: 'ProductDetail', query: { id: `${linkValue}` } });
          if ($commons.isNull(path)) {
            $store.state.fit.clickLink = true;
            // jhkim: vue3: router 참조 방식 변경
            $router.push({ name: 'ProductDetail', query: { id: `${linkValue}` } });
          } else {
            window.open(path, '_blank');
            this.informEvent(path);
          }
          break;
        case 7:
          break;
        case 8:
          // 스토어
          // jhkim: vue3: router 참조 방식 변경
          $router.push({ name: 'StoreHome', query: { id: `${value}` } });
          break;
        case 9:
          // 파이 스토어 프로필
          break;
        case 10:
          // 파이 스토어 대화방
          break;
        case 11:
          // 태그 검색 결과
          bus.$emit('start:loading');
          $router.push('/bbarshop/searchresult?tag=' + value);
          break;
      }
    },

    async autoOrganization(type, id) {
      let autoParams = '';
      if (type === 4) {
        //편성 기획전 정보보기
        await $store.dispatch('exhibitionDetail', id).then(data => {
          if (data) {
            data.special_selling_page.groups.forEach((group, idx) => {
              let product = group.products;
              let productId = [];

              if (product.length > 0) {
                for (let i = 0; i < product.length; i++) {
                  productId[i] = product[i].id;
                }
              }
              autoParams = `?products=${productId}&per_page=20&sort_by=products_list_order`;
            });
          }
        });
      } else if (type === 8) {
        //빠샵 상품목록보기(스토어)
        autoParams = `?companys=${id}&per_page=20`;
      }
      return $store.dispatch('bsProductList', autoParams);
    },

    async trade(value, path) {
      let id = $store.state.bbarshop.recntViewPath === true && $store.state.tvpoint.isLogin ? value.product_id : value.id;
      if (value) {
        const params = {
          trade: {
            type: 1,
            // path: 1,
            company_id: value.company_id,
            product_id: id,
          },
        };
        if (path) params.trade.path = path;
        await $store.dispatch('trades', params);
      }
    },
    async informEvent(url) {
      const pageInform = {
        prevPageInfo: $store.state.fit.pageInform.pageInfo,
        pageInfo: { url: url },
      };
      // $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
      // 	? $store.state.fit.pageInform.pageInfo
      // 	: null;
      // $store.state.fit.pageInform.pageInfo = {};
      // $store.state.fit.pageInform.pageInfo.url = url;

      if (!$commons.isNull(pageInform.prevPageInfo)) {
        await $store.dispatch('informEvent', pageInform);
      }
    },
    pgType(str) {
      $commons.printLog('str', str);
      let pgType;
      switch (str.charAt(8)) {
        case 'C':
          pgType = '1';
          break;
        case 'I':
          pgType = '2';
          break;
        case 'K':
          pgType = '3';
          break;
        case 'N':
          pgType = '4';
          break;
        case 'S':
          pgType = '5';
          break;
      }
      return String(pgType);
    },
    async pushLink(linkValue, linkType) {
      await $store.dispatch('fitPageList');

      const fitMenus = FTInitSetUp.makeMenus(Service.FIT);
      // $store.commit('setFitMenus', fitMenus);
      await $store.commit('setFitMenus', fitMenus);

      //빠숍 메뉴 탭 이름 변경
      const bbarshopMenus = FTInitSetUp.makeMenus(Service.BSHOP);
      // $store.commit('setBbarshopMenus', bbarshopMenus);
      await $store.commit('setBbarshopMenus', bbarshopMenus);

      // $store.commit('setSalesPlatform', 'BBARS00001');
      // $store.commit('setMallCode', 'PUSH');

      switch (linkType) {
        case 1:
          break;
        case 2: {
          //내부 페이지 이동
          const depth = [...$store.state.fit.pageDepth1, ...$store.state.fit.pageDepth2];
          const data = depth.find(data => data.id === linkValue);
          $commons.printLog('pushLink linkType=2 data : ', JSON.stringify(data));

          // jhkim: vue3: router 참조 방식 변경
          if (!$commons.isNull(data) && data.menu_type === 1 && data.depth === 1) {
            await $router.push('/initPage');
            await bus.$emit('fitSetPageLink', Service.FIT, Number(data.idx));
          } else if (!$commons.isNull(data) && data.menu_type === 3 && data.depth === 1) {
            await $router.push('/bbarshop');
            await bus.$emit('bsSetPageLink', Service.BSHOP, Number(data.idx));
          }
          break;
        }
        case 3: {
          const depth = [...$store.state.fit.pageDepth1, ...$store.state.fit.pageDepth2];
          const data = depth.find(data => data.id === linkValue);
          $commons.printLog('pushLink linkType=3 data : ', JSON.stringify(data));
          // jhkim: vue3: router 참조 방식 변경
          $router.push({ name: 'EventDetailView', query: { id: `${linkValue}` } });
          break;
        }
        case 4: {
          // 기획전
          // jhkim: vue3: router 참조 방식 변경
          $router.push({ name: 'ExhibitionDetail', query: { id: `${linkValue ? linkValue : linkValue}` } });
          break;
        }
        case 5: {
          if ($store.state.common.deviceType === 3) {
            this.$bridge.call('callSubWebView', linkValue);
            this.$router.replace('/initPage');
          } else {
            window.open(linkValue, '_blank');
          }
          this.informEvent(linkValue);
          break;
        }
        case 6:
          // 상품 상세
          // jhkim: vue3: router 참조 방식 변경
          $router.push({ name: 'ProductDetail', query: { id: `${linkValue}` } });
          break;
        case 7:
          break;
        case 8:
          // 스토어
          // jhkim: vue3: router 참조 방식 변경
          $router.push({ name: 'StoreHome', query: { id: `${linkValue}` } });
          break;
        case 9:
          // 파이 스토어 프로필
          break;
        case 10:
          // 파이 스토어 대화방
          break;
        case 11:
          // 검색 결과
          break;
      }
    },
    /* 자동로그인 */
    async autoLogin() {
      $commons.printLog('☆ fitMixin autoLogin init');

      const userId = await $bridge.shared().userId();
      const _vue = this;
      const params = {
        termType: 'M002002',
        deviceId: userId,
      };

      this.$store.dispatch('autoLogin', params).then(res => {
        if (parseInt(res.resultCode) === 0) {
          $commons.printLog('☆ fitMixin autoLogin success');
          $bridge.shared().setUserId($utils.makeNumber(res.celNo));
          $bridge.shared().setStringValue('session', res.jsessionId);
          $store.commit('setLoginToken', res.loginToken);

          $utils.sequenceEx([
            this.getUser,
            this.saveLog,
            this.manageAutoTransfer,
            this.getAgrmntList,
            this.$router.push('/initPage'),
            function () {
              $store.commit('setIsBack', true);
              $commons.printLog('☆ fitMixin autoLogin success initPage');
              _vue.paiLogin();
            },
          ]);
        } else {
          $bridge.setStringValue('userPhone', '');
          $bridge.setStringValue('userId', '');
          $bridge.setStringValue('session', '');
          $bridge.setStringValue('reqDate', '');
          $bridge.setStringValue('access_token', '');
          $bridge.setStringValue('renewal_token', '');
          $bridge.setStringValue('loginToken', '');
          $bridge.setStringValue('autoLoginYn', 'N');

          // 스토어 정보 날리기
          $store.commit('resetPointState');
          $store.commit('resetRpState');
          $store.commit('resetUserState');
          $store.commit('resetTvpayState');
          $store.commit('resetPaiState');
          $router.push('/initPage');
        }
      });
    },
    saveLog() {
      $commons.printLog('☆ fitMixin saveLog init');

      const _vue = this;
      const deferred = this.$utils.defer();
      const logMessage = 'tvinfo : [' + JSON.stringify($store.state.tvpoint.tvInfo) + '] index.vue';

      const params = {
        sessionId: $store.getters.getSession,
        deviceId: '',
        tvTermId: Object.keys($store.state.tvpoint.tvInfo).length === 0 ? '' : $store.state.tvpoint.tvInfo.tvTermId,
        logMessage,
      };
      $store.dispatch('saveLog', params);

      deferred.resolve();
      return deferred.promise;
    },
    getUser() {
      const _vue = this;
      const deferred = this.$utils.defer();
      $commons.printLog('☆ fitMixin getUser init');

      const data = $store.dispatch('getUser', { termType: $store.state.tvpoint.termType });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          $commons.printLog('fitMixin getUser success data : ', data);
          // 전달 받은 정보 저장
          $store.commit('saveGetUser', data);
          // 비밀번호 설정 시 휴대폰번호와 생년월일 설정 불가로 앱카드 로그인 한 회원 정보 저장
          $store.commit('setUserCel', $utils.makeNumber(data.celNo));
          $store.commit('setUserBirthDate', data.userBirthDay);
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    /** 자동전환 리스트 가져오기 */
    manageAutoTransfer() {
      const _vue = this;
      const deferred = _vue.$utils.defer();
      const data = $store.dispatch('manageAutoTransfer', { manageType: 'S', termType: $store.state.tvpoint.termType });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (data.dataYN === 'Y') {
            $store.commit('isAutoTrans', 'Y');
            $store.commit('setAutoTransDetail', data);
          } else {
            $store.commit('isAutoTrans', 'N');
          }
          deferred.resolve();
        } else {
          deferred.resolve();
          // deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    /** 화면 기준 사용자의 동의 정보 목록 호출 */
    getAgrmntList() {
      const _vue = this;
      const deferred = this.$utils.defer();

      $commons.printLog('☆ fitMixin getAgrmntList init');

      const data = $store.dispatch('getAgrment', { code: 'AGR_0001', clientType: $store.state.tvpoint.deviceName });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (data.isYN !== 'Y') {
            deferred.reject([_vue, data, 'setAgrmntNewTerms']);
          } else {
            $store.commit('setAgrmntList', data);
            deferred.resolve();
          }
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
  },
};
