<template>
  <transition name="fade">
    <div v-if="!$utils.isNull(getAlertPopup.popupType)" id="layerWrap" class="layer-wrap">
      <div class="pop-layer">
        <component
          :is="getAlertPopup.popupType"
          view="true"
          :title="getAlertPopup.popupTitle"
          :content="getAlertPopup.popupContent"
          :type="getAlertPopup.popupTransType"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import FriendsPopup from '@/components/tvpoint/popup/AlertPopupFrame/FriendsPopup';
import TransAgreePopup from '@/components/tvpoint/popup/AlertPopupFrame/TransAgreePopup.vue';
import FamilyPopup from '@/components/tvpoint/popup/AlertPopupFrame/FamilyPopup';
import DeleteConfirm from '@/components/tvpoint/popup/AlertPopupFrame/DeleteConfirm';
import LoginPopup from '@/components/tvpoint/popup/AlertPopupFrame/LoginPopup';
import LogoutPopup from '@/components/tvpoint/popup/AlertPopupFrame/LogoutPopup';
import ErrorPopup from '@/components/tvpoint/popup/AlertPopupFrame/ErrorPopup';
import TelConfirm from '@/components/tvpoint/popup/AlertPopupFrame/TelConfirm';
import PaycoPopup from '@/components/tvpoint/popup/AlertPopupFrame/PaycoPopup';
import PwdConfirm from '@/components/tvpoint/popup/AlertPopupFrame/PwdConfirm';
import NeedToSubscribePopup from '@/components/pai/AlertPopupFrame/NeedToSubscribePopup';
import SubscribeSuccessPopup from '@/components/pai/AlertPopupFrame/SubscribeSuccessPopup';
import HidePopup from '@/components/pai/confirmPopupFrame/HidePopup';
import ReadPopup from '@/components/pai/confirmPopupFrame/ReadPopup';
import CustomPopup from '@/components/tvpoint/popup/AlertPopupFrame/CustomPopup';
import CancelExchangeAuto from '@/components/tvpoint/popup/AlertPopupFrame/CancelExchangeAuto';
import DeletePayment from '@/components/tvpoint/popup/AlertPopupFrame/DeletePayment';
import NeedToLoginPopup from '@/components/bbarshop/alertPopupFrame/NeedToLoginPopup';
import ZzimDeviceCheckPopup from '@/components/bbarshop/alertPopupFrame/ZzimDeviceCheckPopup';
import ZzimConfirmPopup from '@/components/bbarshop/confirmPopupFrame/ZzimConfirmPopup.vue';
import TagsConfirmPopup from '@/components/bbarshop/confirmPopupFrame/TagsConfirmPopup.vue';
import OrderCancelPopup from '@/components/orderPayment/AlertPopupFrame/OrderCancelPopup';
import NoCancelPopup from '@/components/orderPayment/AlertPopupFrame/NoCancelPopup';
import OrderLoginPopup from '@/components/bbarshop/alertPopupFrame/OrderLoginPopup';
import DeleteUserPopup from '@/components/common/popup/DeleteUserPopup';
import AppInstallPopup from '@/components/tvpoint/popup/AlertPopupFrame/AppInstallPopup';
import CancelPassPopup from '@/components/tvpay/alertPopupFrame/CancelPassPopup';
import CancelPassAgreePopup from '@/components/tvpay/alertPopupFrame/CancelPassAgreePopup';
import EndCancelMelonPopup from '@/components/tvpay/alertPopupFrame/EndCancelMelonPopup';
import MelonCancelPassPopup from '@/components/tvpay/alertPopupFrame/MelonCancelPassPopup';

import { mapGetters } from 'vuex';

export default {
  components: {
    LoginPopup,
    LogoutPopup,
    ErrorPopup,
    TransAgreePopup,
    FamilyPopup,
    DeleteConfirm,
    PaycoPopup,
    FriendsPopup,
    TelConfirm,
    PwdConfirm,
    NeedToSubscribePopup,
    SubscribeSuccessPopup,
    HidePopup,
    ReadPopup,
    DeletePayment,
    CustomPopup,
    CancelExchangeAuto,
    NeedToLoginPopup,
    ZzimDeviceCheckPopup,
    ZzimConfirmPopup,
    TagsConfirmPopup,
    OrderCancelPopup,
    NoCancelPopup,
    OrderLoginPopup,
    DeleteUserPopup,
    AppInstallPopup,
    CancelPassPopup,
    CancelPassAgreePopup,
    EndCancelMelonPopup,
    MelonCancelPassPopup,
  },

  data() {
    return {
      pages: '',
    };
  },

  computed: {
    ...mapGetters(['getAlertPopup']),
  },

  watch: {
    pages(val) {
      if (this.$utils.isNull(val)) {
        window.removeEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      } else {
        window.addEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      }
    },
    getAlertPopup() {},
  },

  methods: {
    confirm() {
      this.$store.commit('alertPopup', null);
    },
    handleTouchmove(e) {
      this.$utils.printLog('[AlertPopup] test ! ');
      e.stopPropagation();
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-layer {
  vertical-align: middle;
  width: 580px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px;
}

.pop-title {
  display: inline-block;
  margin: 40px 40px 0 40px;
  height: 32px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.6px;
  text-align: left;
  width: 100%;
  color: #343534;
}
.layer-wrap {
  // display: none;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  // margin-right: -.25em;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  // width: 300px;
  width: 580px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px;
}
.contents-container {
  padding: 35px;
}
.txt_contents {
  width: 100%;
  height: 160px;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.btn_confirm {
  display: inline-block;
  width: 100%;
  height: 90px;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: right;
  border-radius: 16px;
  background-color: #ffffff;

  color: #648cff;
}

.btn_confirm span {
  display: inline-block;
  margin-right: 40px;
}
//margin: 0 40px 40px 0;
</style>
