<template>
  <div v-if="!$commons.isNull(payCardList)" :class="cardWrapperType ? 'card-wrapper' : 'card-wrapper-none'">
    <swiper
      :class="cardListExist ? 'card-swiper' : 'card-swiper-one'"
      v-bind="swiperOptions"
      style="width: auto"
      @swiper="onSwiper"
      @slide-change="slideChange"
      @click="clickSlide"
    >
      <swiper-slide v-for="(item, index) in payCardList" :key="index" style="width: auto">
        <CardRollingContent
          v-if="item.isMobileUser === 'Y'"
          :card="item"
          :img-size="imgSize"
          :gap-size="gapSize"
          :active-check="activeIndex !== index"
          :pay="pay"
          :active-index="activeIndex"
        />
      </swiper-slide>
      <swiper-slide v-if="cardWrapperType" style="width: auto" @click="clickEvent('otherCard')">
        <div class="card-register d-flex c-center r-center">
          <div
            class="new-add-wrapper d-flex c-center r-center grid"
            :style="{ border: isLastSlide ? '4px solid #2b2d2f' : '2px solid #dadcde' }"
            style="margin-right: 24px"
          >
            <img src="@/assets/img/bbarshop/img_pay2.svg" style="margin-bottom: 12px" />
            <CommonText font-size="28" font-color="#2b2d2f" spacing="-0.56" font-weight="500">다른 결제수단 선택</CommonText>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide v-else style="width: auto" @click="clickEvent('newCard')">
        <div class="card-register d-flex c-center r-center">
          <div
            class="new-add-wrapper d-flex c-center r-center grid"
            :style="{ border: isLastSlide ? '4px solid #2b2d2f' : '2px solid #dadcde' }"
            style="margin-right: 24px"
          >
            <img src="@/assets/img/bbarshop/btn_plus_card.svg" style="margin-bottom: 12px" />
            <CommonText font-size="28" font-color="#2b2d2f" spacing="-0.56" font-weight="500">신규 카드 등록</CommonText>
          </div>
        </div>
      </swiper-slide>

      <!-- 신규카드 추가(카드사 선택형) : 시나리오 변경으로 인해 주석처리 mjkim 230705 -->
      <!--			<swiper-slide style="width: auto">-->
      <!--				<div class="card-register d-flex c-center r-center">-->
      <!--					<div-->
      <!--						class="new-add-wrapper"-->
      <!--						:style="{ border: activeIndex === payCardList.cardList.length ? '4px solid #2b2d2f' : '2px solid #dadcde' }"-->
      <!--						style="margin-right: 24px"-->
      <!--					>-->
      <!--						<CommonText fontSize="28" fontColor="#2b2d2f" spacing="-0.56" fontWeight="500" style="margin-bottom: 41px">신규카드</CommonText>-->
      <!--						<CardSelect-->
      <!--							:cardList="cardCompanyList.cardList"-->
      <!--							:newSelect="true"-->
      <!--							:selectType="'card'"-->
      <!--							:activeCheck="activeIndex !== payCardList.cardList.length"-->
      <!--							style="margin-bottom: 8px"-->
      <!--						></CardSelect>-->
      <!--						<CardSelect-->
      <!--							:optionList="pay ? optionList.option.options : optionList.option.option1"-->
      <!--							:newSelect="true"-->
      <!--							:activeCheck="activeIndex !== payCardList.cardList.length"-->
      <!--							:activeIndex="activeIndex"-->
      <!--						></CardSelect>-->
      <!--					</div>-->
      <!--				</div>-->
      <!--			</swiper-slide>-->

      <!-- 신규 계좌 : 개발범위에서 계좌 결제 제외됨에 따라 주석처리 230426 -->
      <!--			<swiper-slide style="width: auto">-->
      <!--				<div class="card-register d-flex c-center r-center" :key="idx" :style="{ marginRight: type === 'card-wrapper' ? '50px' : '' }">-->
      <!--					<div-->
      <!--						class="new-add-wrapper"-->
      <!--						:style="{-->
      <!--							border: activeIndex === payCardList.cardList.length + 1 ? '4px solid #2b2d2f' : '2px solid #dadcde',-->
      <!--							marginRight: type !== 'card-wrapper' && (!payCardList.cardList || payCardList.cardList.length === 0) ? '120px' : '',-->
      <!--						}"-->
      <!--					>-->
      <!--						<CommonText fontSize="28" fontColor="#2b2d2f" spacing="-0.56" fontWeight="500" style="margin-bottom: 41px">신규계좌</CommonText>-->
      <!--						<div class="account-box d-flex c-center" @click="clickEvent('registerNewAccount')">-->
      <!--							<img src="@/assets/img/common/ic_plus1.svg" style="margin-right: 8px; margin-left: 16px" />-->
      <!--							<CommonText fontSize="24" fontColor="#5d5f61" spacing="-0.96" fontWeight="normal">계좌 등록하고 결제하기</CommonText>-->
      <!--						</div>-->
      <!--					</div>-->
      <!--				</div>-->
      <!--			</swiper-slide>-->
    </swiper>

    <!--		<div v-if="cardWrapperType" class="d-flex onclick-box">-->
    <div class="d-flex onclick-box" :style="!cardWrapperType ? 'margin-top: 33px;' : ''">
      <div class="btn-container" style="margin-right: 8px">
        <input id="onClickPay" v-model="onClickPay" type="checkbox" class="checkbox" @click="clickEvent('onClickPayCheck')" />
        <label for="onClickPay">
          <div class="check-bg">
            <div class="check-btn"></div>
          </div>
        </label>
      </div>
      <CommonText font-size="26" font-color="#2b2d2f" spacing="-1.3" font-weight="normal" style="margin-right: 8px">원클릭 결제</CommonText>
      <img src="@/assets/img/bbarshop/img_info2.svg" @click="clickEvent('onclickInfo')" />
    </div>
  </div>
</template>

<script>
import 'swiper/css';

import CommonText from '@/components/common/text/CommonText';
import CardRollingContent from '@/components/orderPayment/CardRollingContent.vue';
import { mapGetters } from 'vuex';
import { fitMixin } from '@/utils/fitMixin';
import $store from '@/store';

export default {
  name: 'CardRollingContentWrapper',
  components: {
    CommonText,
    CardRollingContent,
    // BorderBasic,
    // CategorySwiper,
    // CardSelect,
  },
  mixins: [fitMixin],
  props: {
    dataType: {
      type: String,
      default: '',
    },
    dataList: {
      type: Object,
      default: () => {},
    },
    itemList: {
      type: Array,
      default: () => [],
    },
    idx: {
      type: Number,
      default: 0,
    },
    maxItem: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
    pay: {
      type: Boolean,
      default: false,
    },
    cardIdx: {
      type: Number,
      default: 0,
    },
  },

  emits: ['onClickPayState', 'clickEvent', 'cashReceiptClose', 'cardActvieIndex', 'accountActive'],

  data() {
    return {
      swiper: null,
      swiperOptions: {
        touchMoveStopPropagation: true,
        // freeMode: true,
        freeMode: false,
        slidesPerView: 'auto',
        slidesOffsetBefore: 128,
        // slidesOffsetBefore: 160,
        // slidesOffsetBefore: 40,
        // slidesOffsetAfter: 128,
        slidesOffsetAfter: 160,
        followFinger: false,
        slideToClickedSlide: true, // 해당 슬라이드 클릭 시 슬라이드 위치로 이동
        nested: true,
      },
      status: 'off',
      imgSize: {
        width: '245px',
        height: '245px',
      },
      gapSize: {
        right: '16px',
        bottom: '16px',
      },
      optionList: {
        option: {
          option1: [{ instType: '1', name: '일시불', installment: '00' }],
          options: [
            { instType: '1', name: '일시불', installment: '00' },
            { instType: '1', name: '2개월 할부', installment: '02' },
            { instType: '1', name: '3개월 할부', installment: '03' },
            { instType: '1', name: '4개월 할부', installment: '04' },
            { instType: '1', name: '5개월 할부', installment: '05' },
            { instType: '1', name: '6개월 할부', installment: '06' },
            { instType: '1', name: '7개월 할부', installment: '07' },
            { instType: '1', name: '8개월 할부', installment: '08' },
            { instType: '1', name: '9개월 할부', installment: '09' },
            { instType: '1', name: '10개월 할부', installment: '10' },
            { instType: '1', name: '11개월 할부', installment: '11' },
            { instType: '1', name: '12개월 할부', installment: '12' },
          ],
        },
      },
      activeIndex: 0,
      onClickPay: true, //원클릭 결제 여부
      pinAuthYN: 'N',
    };
  },

  computed: {
    ...mapGetters(['isLogin', 'getDeviceType']),
    payCardList() {
      return !this.$commons.isNull(this.$store.state.orderPayment.payCardList) ? this.$store.state.orderPayment.payCardList : {};
    },
    cardCompanyList() {
      return !this.$commons.isNull(this.$store.state.orderPayment.cardCompanyList) ? this.$store.state.orderPayment.cardCompanyList : {};
    },
    cardWrapperType() {
      return this.type === 'card-wrapper';
    },
    cardListExist() {
      return this.payCardList && this.payCardList.length !== 0;
    },
    isLastSlide() {
      return this.activeIndex === this.payCardList.length;
    },
  },

  watch: {
    dataList(newVal, oldVal) {},
    activeIndex: {
      immediate: true,
      handler(newVal) {
        this.$utils.printLog(newVal);
      },
    },
  },

  created() {
    if (this.$store.state.orderPayment.cardSelected) {
      // this.$store.state.orderPayment.cardSelected.pinAuthYN > N 원클릭결제 , Y 티비페이 결제
      this.onClickPay = this.$store.state.orderPayment.cardSelected.pinAuthYN === 'N';
      this.$emit('onClickPayState', this.onClickPay);
    }
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    // jhkim: vue3: swiper click
    clickSlide(sender, event) {
      let realIndex = sender.clickedSlide.swiperSlideIndex;
      this.$emit('clickEvent', 'cardSelect', realIndex, this.payCardList[Number(realIndex)]);
      this.$emit('clickEvent', 'cashReceiptOff'); //현금 영수증 외 다른 카드 선택했을때 현금 영수증 닫기
    },

    clickEvent(sort) {
      switch (sort) {
        case 'registerNewAccount': {
          // this.$emit('clickEvent', 'isRegisterNewAccount', true);
          break;
        }
        case 'otherCard':
          // 빠른 구매 팝업 : 주문/결제 페이지로 이동
          this.$router.push('/bbarshop/orderPaymentDetail');
          break;
        case 'newCard':
          this.$store.commit('setPreviousPage', 'payCardReg'); // 추가 시 카드 등록 후 기존 페이지로 이동
          this.$router.push('/tvpay/register');
          break;
        case 'onClickPayCheck': {
          this.onClickPay = !this.onClickPay;
          this.$emit('onClickPayState', this.onClickPay);
          this.$utils.printLog('onClickPay', this.onClickPay);
          break;
        }
        case 'onclickInfo':
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '결제 시 비밀번호 인증없이 바로 결제할 수 있는 기능입니다.',
            popupTitle: '원클릭 결제란?',
          });
          break;
      }
    },
    slideChange() {
      this.$utils.printLog('슬라이드 체인지', this.$store.state.orderPayment.cardSelected.cardCompany);
      this.$store.state.orderPayment.cardSelected.cardCompany = null;
      // const swiper = this.$refs.bannerSwiper.$swiper;
      const swiper = this.swiper;
      this.activeIndex = swiper.realIndex;

      let accountState = this.activeIndex === this.payCardList.length + 1 ? true : false;
      this.$emit('cashReceiptClose', accountState);
      this.$emit('cardActvieIndex', this.activeIndex);

      this.$store.state.orderPayment.selectDiscount = { instType: '1', name: '일시불', installment: '00' };
      this.$utils.printLog(this.$store.state.orderPayment.selectDiscount);
      if (this.$store.state.orderPayment.payCardList.length - 1 < swiper.realIndex) {
        if (this.$store.state.orderPayment.payCardList.length === swiper.realIndex) {
          //카드사선택
          this.$store.state.orderPayment.cardSelected.cardCompany = 'newCard';
          this.$utils.printLog(
            !this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany),
            this.$store.state.orderPayment.cardSelected.cardCompany,
          );
        } else if (this.$store.state.orderPayment.payCardList.length + 1 === swiper.realIndex) {
          //계좌 선택
          this.$utils.printLog('신규');
          this.$store.state.orderPayment.cardSelected.cardCompany = { cardName: '신규계좌', cardCode: 'P088' };
          //신규계좌로 왔을때 현금영수증 활성화
          this.$emit('accountActive', this.$store.state.orderPayment.cardSelected.cardCompany.cardCode === 'P088');

          this.$utils.printLog(
            !this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany),
            this.$store.state.orderPayment.cardSelected.cardCompany,
          );
        }
      } else {
        //기존
        this.$store.state.orderPayment.cardSelected = this.$store.state.orderPayment.payCardList[swiper.realIndex];
        //계좌로 왔을때 현금영수증 활성화
        this.$emit('accountActive', this.$store.state.orderPayment.cardSelected.cardCode === 'P088');
      }
    },
    goSlide(cardIdx) {
      // const swiper = this.$refs.bannerSwiper.$swiper;
      const swiper = this.swiper;
      swiper.slideTo(cardIdx);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  padding-top: 48px;
  //padding-bottom: 48px;
  padding-bottom: 32px;
  background-color: #f2f3f5;
}
.card-swiper {
  //margin-left: 128px;
  // padding-left: calc((100% - 400px) / 2 - 72px + 30px);
  // // padding-right: calc((100% - 400px) / 2 - 24px + 376px);
  // padding-right: calc((100% - 400px) / 2 - 24px + 420px);
}
.card-swiper-one {
  //margin-left: 128px;
  // padding-left: calc((100% - 400px) / 2);
  // padding-right: calc((100% - 400px) / 2 - 24px + 376px);
  //padding-right: calc((100% - 400px) / 2 - 24px + 420px);
}
.card-register {
  //width: 400px;
  // height: 216px;
  // padding: 20px;
  // border-radius: 16px;
  // background-color: #ffffff;
  //margin-right: 24px;
}
.btn-register {
  width: 170px;
  height: 156px;
  border-radius: 8px;
  background-color: #f2f3f5;
}
.new-add-wrapper {
  width: 360px;
  height: 210px;
  padding: 24px 20px 20px;
  border-radius: 16px;
  border: solid 2px #dadcde;
  background-color: #ffffff;
}
.card-wrapper-none {
  // padding-top: 48px;
  // padding-bottom: 48px;
  // background-color: #f2f3f5;
  margin-top: 40px;
  padding-left: 32px;
}
.account-box {
  width: 100%;
  height: 136px;
  border-radius: 8px;
  background-color: #f2f3f5;
}

//
.onclick-box {
  margin-left: 28px;
  margin-top: 24px;
}
.checkbox {
  display: none;
}
.check-bg {
  width: 72px;
  height: 42px;
  background-color: #c1c3c5;
  border-radius: 28px;
  display: flex;
  align-items: center;
}
.check-btn {
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 80px;
  margin-left: 4px;
  margin-right: 4px;
}
.checkbox:checked + label > .check-bg {
  background-color: #2b2d2f;
}
.checkbox:checked + label .check-btn {
  margin-left: 0;
  transform: translateX(100%);
}
</style>
