<template>
  <div>
    <section class="column__title" @click="clickEvent('option')">
      <ColumnHeader :class="isMore ? 'optionsWrap' : 'optionsoptionsWrapMore'">
        <template #title>
          <div class="options">{{ optionTitle }}</div>
        </template>
        <template #right>
          <img ref="option" :src="require('@/assets/img/common/img_arr_low2.svg')" class="column__title__arrow" alt="" />
        </template>
      </ColumnHeader>
      <div ref="optionMore" class="column__title__more off" style="">
        <div
          v-for="(optionItem, itemIdx) in dataList.option.options"
          :key="itemIdx"
          class="d-flex section"
          style="margin: 25px 0 29px 32px"
        >
          <div @click="clickEvent('check', optionItem, idx)">
            <input
              :id="optionItem.id"
              :ref="optionItem.id"
              v-model="ongoingChecked"
              type="radio"
              name="store"
              :class="optionItem.sold_out === true ? 'sold-out' : 'check-all'"
              :value="optionItem.id"
              :checked="clickOption()"
              :disabled="optionItem.sold_out === true"
            />
            <label :for="optionItem.id"></label>
          </div>
          <CommonText font-size="28" :style="{ color: optionItem.sold_out === true ? '#a8aaac' : '#2b2d2f' }">
            {{ optionItem.name }}
            <span v-if="optionItem.sold_out === true">(품절)</span>
          </CommonText>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ColumnHeader from '@/components/menu/ColumnHeader';
import CommonText from '@/components/common/text/CommonText';
export default {
  name: 'OrderSelect',

  components: {
    ColumnHeader,
    CommonText,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: Number,
      default: 0,
    },
    dataList: {
      type: Object,
      default: () => {},
    },
    isClicked: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['quantity', 'price', 'options', 'ongoingChecked'],

  data() {
    return {
      count: 1,
      btnMinusImg: require('@/assets/img/common/btn_minus.svg'),
      btnPlusImg: require('@/assets/img/common/btn_plus.svg'),
      optionTitle: this.dataList.option.option_title,
      clickId: [],
      ongoingChecked: 0,
      //체크박스 선택 상태
      selectedOptions: [],
      isMore: false,
    };
  },

  watch: {
    isClicked: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.$nextTick(() => {
            this.$refs.optionMore.classList.toggle('off');
            this.$refs.option.classList.toggle('clicked');
          });
        }
      },
    },
  },

  created() {},

  methods: {
    clickEvent(sort, item, idx) {
      switch (sort) {
        case 'option': {
          // 주문서에서 옵션 변경시 발생하는 오류 방지 위해 if문 추가
          if (!this.$commons.isNull(this.$refs.optionMore)) {
            this.$refs.optionMore.classList.toggle('off');
            this.isMore = this.$refs.optionMore.classList.contains('off');
          }
          if (!this.$commons.isNull(this.$refs.option)) {
            this.$refs.option.classList.toggle('clicked');
          }
          break;
        }
        case 'check': {
          //라디오 박스 선택시 드롭박스 닫히기
          this.$refs.optionMore.classList.add('off');
          this.$refs.option.classList.add('clicked');

          if (item.sold_out === true) {
            this.optionTitle = '옵션';
            this.$emit('options', 'soldOut');
            this.$emit('price', 0);
          } else {
            this.optionTitle = item.name;
            this.$emit('quantity', true);
            // this.$emit('price', this.dataList.price_discount);
            if (this.dataList.price_discount === 0) {
              this.$emit('price', this.dataList.price_original + item.add_price);
            } else {
              this.$emit('price', this.dataList.price_discount + item.add_price); //할인금액 + 추가금액
            }

            this.$emit('options', item);
          }
          break;
        }
      }
    },
    clickOption() {
      //버튼 활성화
      this.$emit('ongoingChecked', this.ongoingChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.column__title {
  margin: 40px;

  #{&}__arrow {
    transition: transform 300ms ease-in-out;
    margin-left: 24px;

    &.clicked {
      transition: transform 300ms ease-in-out;
      transform: rotate(180deg);
    }
  }

  #{&}__more {
    & {
      display: block;
    }

    &.off {
      display: none;
    }
  }
}

.optionsWrap {
  width: auto;
  height: 80px;
  padding: 0 32px;
  border: solid 1px #dadcde;
  border-radius: 8px;
}
.optionsoptionsWrapMore {
  width: auto;
  height: 80px;
  padding: 0 32px;
  background-color: #f2f3f5;
  border-radius: 8px 8px 0 0;
}
.options {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -1.12px;
  // text-align: left;
  color: #2b2d2f;
}
.column__title__more {
  overflow: scroll;
  width: 100%;
  height: 254px;
  border: solid 1px #dadcde;
  border-top: none;
}
.check-all:checked + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('~@/assets/img/common/btn_radio_on.svg') no-repeat;
  box-sizing: border-box;
}

.sold-out {
  margin-right: 16px;
  margin-left: 0px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('~@/assets/img/common/btn_radio_disabled.svg') no-repeat;
  box-sizing: border-box;
}

.check-all {
  display: none;
  float: left;
  box-sizing: border-box;
}

.check-all + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('~@/assets/img/common/btn_radio_off.svg') no-repeat;
  box-sizing: border-box;
}
</style>
